import { BiHomeAlt2 } from "react-icons/bi";
import { MdEventNote } from "react-icons/md";
import { RxDashboard } from "react-icons/rx";

export const itemsUser = [
  {
    key: "/",
    icon: <BiHomeAlt2 />,
    label: "Home",
  },
  {
    key: "/user",
    icon: <RxDashboard />,
    label: "Dashboard",
  },
  {
    key: "/user/business-matching",
    icon: <MdEventNote />,
    label: "Business Matching",
  },
  {
    key: "/user/learnings",
    icon: <MdEventNote />,
    label: "Biznids Learnings",
  },
];
