import { PlusOutlined } from "@ant-design/icons";
import { Form, Input, Modal, Radio, Upload, message } from "antd";
import axios from "axios";
import { useCallback, useContext, useEffect, useState } from "react";
import { decryptCookies } from "../../../components/helper/cookieshelper";
import { TestimonialContext } from "../../../context/TestimonialContext";

const { TextArea } = Input;

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const EditTestimonial = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [newData, setNewData] = useState({});
  const [fileList, setFileList] = useState([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [newFileList, setNewFileList] = useState([]);

  const { testimonialId, showEdit, setShowEdit, setFetchTestimonial } =
    useContext(TestimonialContext);

  const { REACT_APP_HOST_API } = process.env;

  const fetchDetail = useCallback(async () => {
    try {
      const { data } = await axios.get(
        REACT_APP_HOST_API + `/api/testimonis/${testimonialId}`,
        { headers: { Authorization: "Bearer " + decryptCookies("token") } }
      );

      if (data?.data?.image) {
        setFileList([
          {
            uid: "-1",
            name: "testimonial",
            status: "done",
            url: data.data.image,
          },
        ]);
      }

      form.setFieldsValue({
        name: data?.data?.name,
        position: data?.data?.position,
        order: data?.data?.order,
        description: data?.data?.description,
        showOnHome: data?.data?.show_on_home,
      });
    } catch (error) {
      alert(error?.response?.data?.message);
    }
  }, [form, REACT_APP_HOST_API, testimonialId]);

  useEffect(() => {
    if (showEdit) fetchDetail();

    return () => setNewData({});
  }, [showEdit, fetchDetail]);

  const onCancelModal = () => {
    form.resetFields();
    setFileList([]);
    setShowEdit(false);
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const handleChange = (param) =>
    (newData[param.target.name] = param.target.value);

  const onSubmit = async () => {
    form
      .validateFields()
      .then(async (values) => {
        setLoading(true);
        try {
          const { data } = await axios.put(
            REACT_APP_HOST_API + "/api/testimonis/" + testimonialId,
            newFileList.length > 0
              ? {
                  ...values,
                  image: newFileList[0],
                }
              : newData,
            {
              headers: {
                Authorization: "Bearer " + decryptCookies("token"),
                "Content-Type": "multipart/form-data",
              },
            }
          );
          message.success(data.message);
          setFetchTestimonial(true);
          onCancelModal();
        } catch (error) {
          alert(error?.response?.data?.message);
        } finally {
          setLoading(false);
        }
      })
      .catch(() => {
        return;
      });
  };

  return (
    <Modal
      open={showEdit}
      okText="Submit"
      cancelText="Batal"
      onOk={onSubmit}
      onCancel={onCancelModal}
      okButtonProps={{ loading }}
    >
      <Form form={form} layout="vertical">
        <Form.Item name="name" label="Nama" rules={[{ min: 3 }]}>
          <Input name="name" onChange={handleChange} />
        </Form.Item>
        <Form.Item
          name="position"
          label="Pekerjaan(Posisi)"
          rules={[{ min: 3 }]}
        >
          <Input name="position" onChange={handleChange} />
        </Form.Item>
        <Form.Item name="description" label="Testimonial" rules={[{ min: 7 }]}>
          <TextArea name="description" onChange={handleChange} rows={8} />
        </Form.Item>
        <Form.Item label="Photo">
          <Upload
            fileList={fileList}
            accept=".jpg,.jpeg,.png"
            listType="picture-card"
            onPreview={handlePreview}
            onRemove={() => false}
            onChange={(file) => {
              if (file?.file.size > 3000000) return;
              else setFileList(file.fileList);
            }}
            beforeUpload={(file) => {
              if (file.size <= 3000000) {
                setFileList([file]);
                setNewFileList([file]);
                return false;
              }
              if (file.size > 3000000) {
                message.error("Maks File 3MB");
              }
            }}
          >
            {fileList?.length > 1
              ? null
              : fileList.length === 0
              ? uploadButton
              : "Ganti"}
          </Upload>
        </Form.Item>
        <Form.Item
          name="order"
          label="Order"
          rules={[
            { required: true },
            {
              pattern: new RegExp("^([0-9]*)$"),
              message: "Masukkan hanya Nomor",
            },
          ]}
        >
          <Input name="order" onChange={handleChange} />
        </Form.Item>
        <Form.Item name="showOnHome" label="Tampil di Homepage">
          <Radio.Group onChange={(e) => (newData.showOnHome = e)}>
            <Radio value={true}>tampil</Radio>
            <Radio value={false}>tidak</Radio>
          </Radio.Group>
        </Form.Item>
      </Form>
      <Modal
        open={previewOpen}
        title={previewTitle}
        footer={null}
        width={800}
        style={{ top: 20 }}
        onCancel={() => setPreviewOpen(false)}
      >
        <img alt="example" style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </Modal>
  );
};

export default EditTestimonial;
