import { Form, Input, Modal, Skeleton, message } from "antd";
import axios from "axios";
import { decryptCookies } from "components/helper/cookieshelper";
import { useBanksDetail } from "hook/banks-hook/apiCalls";
import { useEffect, useState } from "react";

const { REACT_APP_HOST_API: url } = process.env;

const EditBank = ({ id, show, onCancel, onUpdate }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const { data, isLoading, refetch } = useBanksDetail(id);

  useEffect(() => {
    if (show) refetch();

    if (data)
      form.setFieldsValue({
        name: data?.data?.name,
        noRek: data?.data?.no_rek,
        an: data?.data?.an,
      });
  }, [data, form, show, refetch]);

  const handleSubmit = async () => {
    const values = await form.validateFields();

    setLoading(true);
    try {
      const { data } = await axios.put(url + `/api/rekenings/${id}`, values, {
        headers: { Authorization: "Bearer " + decryptCookies("token") },
      });
      message.success(data.message);
      onUpdate();
      onCancelModal();
    } catch (error) {
      alert(error.message);
    } finally {
      setLoading(false);
    }
  };

  const onCancelModal = () => {
    form.resetFields();
    onCancel();
  };

  return (
    <Modal
      open={show}
      okText="Submit"
      onCancel={onCancelModal}
      onOk={handleSubmit}
      okButtonProps={{ loading }}
    >
      {isLoading ? (
        <Skeleton active />
      ) : (
        <Form form={form} layout="vertical">
          <Form.Item label="Nama Bank" name="name">
            <Input />
          </Form.Item>
          <Form.Item
            label="No Rekening"
            name="noRek"
            rules={[
              {
                pattern: new RegExp("^([0-9]*)$"),
                message: "Masukkan hanya Nomor",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="Atas Nama" name="an">
            <Input />
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
};

export default EditBank;
