import { Form, Input, Modal, message } from "antd";
import axios from "axios";
import { decryptCookies } from "components/helper/cookieshelper";
import { setShowEditPassword } from "features/member/userMember-slice";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const { REACT_APP_HOST_API: url } = process.env;

const AdminEditUserPassword = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const { userId, showEditPassword } = useSelector((state) => state.userMember);

  const handleSubmit = async () => {
    const values = await form.validateFields();

    setLoading(true);
    try {
      const { data } = await axios.put(
        url + `/api/user/change-password/${userId}`,
        values,
        { headers: { Authorization: "Bearer " + decryptCookies("token") } }
      );
      message.success(data.message);
      form.resetFields();
      dispatch(setShowEditPassword(false));
    } catch (error) {
      alert(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      open={showEditPassword}
      okText="Submit"
      onCancel={() => dispatch(setShowEditPassword(false))}
      onOk={handleSubmit}
      okButtonProps={{ loading }}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          label="Password"
          name="password"
          hasFeedback
          rules={[{ required: true, min: 6 }]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label="Password Confirmation"
          name="password_confirmation"
          hasFeedback
          rules={[
            { required: true },
            { min: 6 },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }

                return Promise.reject(new Error("Password must be same!"));
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AdminEditUserPassword;
