import { CalendarOutlined } from "@ant-design/icons";
import { Button, DatePicker, Empty } from "antd";
import Container from "components/Container";
import { handleLongText } from "components/helper/handleLongText";
import {
  setFromDate,
  setToDate,
} from "features/business-matching/business-matching-slice";
import { CiLocationOn } from "react-icons/ci";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const Events = ({ dataUpcoming, dataPast }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <Container className="my-20">
      <div className="w-[90%] mx-auto">
        <div className="flex flex-wrap mb-8">
          <h2 className="text-2xl font-bold mr-8 mb-2">Upcoming Events</h2>
          <div className="flex gap-4">
            <div className="filter">
              <DatePicker
                placeholder="Tanggal Awal"
                style={{ width: "100%" }}
                onChange={(e) => {
                  if (e === null) dispatch(setFromDate(""));
                  else dispatch(setFromDate(e.format("YYYY-MM-DD")));
                }}
                className="border border-gray-300"
              />
            </div>
            <div className="filter">
              <DatePicker
                placeholder="Tanggal Akhir"
                style={{ width: "100%" }}
                onChange={(e) => {
                  if (e === null) dispatch(setToDate(""));
                  else dispatch(setToDate(e.format("YYYY-MM-DD")));
                }}
                className="border border-gray-300"
              />
            </div>
          </div>
        </div>

        {dataUpcoming?.data.data.length > 0 ? (
          <div className="flex items-center gap-10 flex-wrap">
            {dataUpcoming?.data?.data?.map((item, index) => (
              <div key={index}>
                <div
                  key={item.id}
                  className="w-[300px] rounded-sm overflow-hidden border border-gray-300 hover:shadow-lg duration-200"
                >
                  <img
                    src={item.image_banner}
                    alt="event"
                    className="w-[100%] h-[150px] object-cover object-center"
                  />
                  <div className="pt-2 pb-3 px-3">
                    <h2 className="text-customblue2-0 text-[18px] font-[500] my-1">
                      {item.title}
                    </h2>
                    <p className="font-[300] text-gray-600 text-[15px] mb-4">
                      {handleLongText(item.description, 60)}
                    </p>

                    <p className="font-[300] text-gray-500 text-[13px] flex gap-2 items-center mb-6">
                      <CalendarOutlined /> {item.from_date} - {item.to_date}
                    </p>

                    <div className="flex flex-wrap justify-between items-center">
                      <p className="font-[300] text-gray-500 text-[13px] flex gap-2 items-center mb-2">
                        <CiLocationOn />
                        {handleLongText(item.location, 40)}
                      </p>

                      <Button
                        className="border-2 border-orange-500 hover:!border-orange-500 hover:bg-orange-500 hover:!text-white"
                        onClick={() =>
                          navigate(`/business-matching/${item.id}`)
                        }
                      >
                        Read more
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <Empty description="No Upcoming Event" className="w-[200px] my-20" />
        )}

        <div className="flex flex-wrap mb-8 mt-40">
          <h2 className="text-2xl font-bold">Past Events</h2>
        </div>

        <div className="flex items-center gap-10 flex-wrap">
          {dataPast?.data?.data?.map((item, index) => (
            <div key={index}>
              <div
                key={item.id}
                className="w-[300px] rounded-sm overflow-hidden border border-gray-300 hover:shadow-lg duration-200"
              >
                <img
                  src={item.image_banner}
                  alt="event"
                  className="w-[100%] h-[150px] object-cover object-center grayscale"
                />
                <div className="pt-2 pb-3 px-3">
                  <h2 className="text-customblue2-0 text-[18px] font-[500] my-1">
                    {item.title}
                  </h2>
                  <p className="font-[300] text-gray-600 text-[15px] mb-4">
                    {handleLongText(item.description, 60)}
                  </p>

                  <p className="font-[300] text-gray-500 text-[13px] flex gap-2 items-center mb-8">
                    <CalendarOutlined /> {item.from_date} - {item.to_date}
                  </p>

                  <div className="flex flex-wrap justify-between items-center">
                    <p className="font-[300] text-gray-500 text-[13px] flex gap-2 items-center mb-2">
                      <CiLocationOn />
                      {handleLongText(item.location, 40)}
                    </p>

                    <Button
                      className="border-2 border-orange-500 hover:!border-orange-500 hover:bg-orange-500 hover:!text-white"
                      onClick={() => navigate(`/business-matching/${item.id}`)}
                    >
                      Read more
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Container>
  );
};

export default Events;
