import { Input, Popconfirm, Space, Table, Tag } from "antd";
import { DeleteApi } from "api/DeleteApi";
import TableHeader from "components/TableHeader";
import { setFetchTrade } from "features/trade-mission/trade-mission-slice";
import { useProjectsPagination } from "hook/projects-hook/apiCalls";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const MasterProjects = () => {
  const [keyword, setKeyword] = useState("");
  const [dataTable, setDataTable] = useState({ limit: 20, page: 1 });

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { fetchTrade } = useSelector((state) => state.tradeMission);

  const { data, isLoading, refetch } = useProjectsPagination({
    keyword,
    dataTable,
  });

  useEffect(() => {
    refetch();

    return () => dispatch(setFetchTrade(false));
  }, [refetch, dispatch, fetchTrade]);

  const dataSource = data?.data?.data?.map((x, i) => ({
    ...x,
    key: x.id,
    index: i + 1,
  }));

  const columns = [
    { title: "No", dataIndex: "index", width: 50 },
    { title: "Nama", dataIndex: "title" },
    {
      dataIndex: "banner",
      render: (x) => (
        <img
          src={x}
          alt="banner"
          width={100}
          height={100}
          className="object-cover object-center rounded-md"
        />
      ),
    },
    { title: "PJ", dataIndex: "responsibility" },
    { title: "Tanggal", dataIndex: "date" },
    {
      title: "Aksi",
      dataIndex: "id",
      align: "center",
      render: (id) => (
        <Space>
          <Popconfirm
            title="Yakin ingin menghapus?"
            okText="Hapus"
            cancelText="Batal"
            onConfirm={() =>
              DeleteApi({ url: "/api/trade-missions/", dataId: id, refetch })
            }
          >
            <Tag color="magenta" style={{ cursor: "pointer" }}>
              Hapus
            </Tag>
          </Popconfirm>
          <Tag
            color="orange"
            style={{ cursor: "pointer" }}
            onClick={() => navigate(`edit/${id}`)}
          >
            Ubah
          </Tag>
          <Tag
            color="blue"
            style={{ cursor: "pointer" }}
            onClick={() => navigate(id)}
          >
            Detail
          </Tag>
        </Space>
      ),
    },
  ];

  const pagination = {
    current: dataTable.page,
    pageSize: dataTable.limit,
    defaultPageSize: dataTable.limit,
    total: data?.data.meta.total,
    showSizeChanger: true,
    pageSizeOptions: [10, 20, 50, 100],
    onChange: (curr, size) => {
      setDataTable((value) => {
        return {
          ...value,
          page: curr,
          limit: size,
        };
      });
    },
  };
  return (
    <div>
      <TableHeader title="Projects" onClick={() => navigate("create")} />
      <div className="table-filter">
        <div className="filter">
          <Input
            placeholder="cari nama"
            onChange={({ target: { value } }) => setKeyword(value)}
          />
        </div>
      </div>
      <Table
        dataSource={dataSource}
        columns={columns}
        size="small"
        loading={isLoading}
        pagination={pagination}
        scroll={{ x: 800 }}
      />
    </div>
  );
};

export default MasterProjects;
