import { Image } from "antd";
import { useUsersDetail } from "hook/users-hook/apiCalls";
import { useParams } from "react-router-dom";
import DividerComponent from "components/Divider";
import { Fragment, useEffect } from "react";
import Loading from "components/Loading";

const DetailMasterUsers = () => {
  const { user_id } = useParams();

  const { data, isLoading, refetch } = useUsersDetail(user_id);

  useEffect(() => {
    refetch();
  }, [refetch]);

  return (
    <Fragment>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="detail-corporate">
          <DividerComponent>Detail User</DividerComponent>
          <Image width={300} src={data?.data?.image} />
          <table>
            <tbody>
              <tr>
                <td className="p-2">Name: </td>
                <td className="p-2">{data?.data?.username}</td>
              </tr>
              <tr>
                <td className="p-2">Email: </td>
                <td className="p-2">{data?.data?.email}</td>
              </tr>
              <tr>
                <td className="p-2">No Handphone: </td>
                <td className="p-2">{data?.data?.phone}</td>
              </tr>
              <tr>
                <td className="p-2">Interest: </td>
                <td className="p-2">{data?.data?.interest}</td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </Fragment>
  );
};

export default DetailMasterUsers;
