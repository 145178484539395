import { PlusOutlined } from "@ant-design/icons";
import {
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Radio,
  Upload,
  message,
} from "antd";
import axios from "axios";
import { decryptCookies } from "components/helper/cookieshelper";
import {
  setFetchTrade,
  setShowCreate,
} from "features/trade-mission/trade-mission-slice";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const { TextArea } = Input;
const formatDate = "YYYY-MM-DD";

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const CreateTradeMission = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [bannerList, setBannerList] = useState([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");

  const dispatch = useDispatch();
  const { showCreate } = useSelector((state) => state.tradeMission);

  const { REACT_APP_HOST_API } = process.env;

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const onSubmit = async () => {
    form
      .validateFields()
      .then(async (values) => {
        if (fileList.length === 0) {
          return message.error("Harap masukkan gambar event");
        }

        setLoading(true);
        try {
          const { data } = await axios.post(
            REACT_APP_HOST_API + "/api/trade-missions",
            {
              ...values,
              fromDate: values.fromDate.format(formatDate),
              toDate: values.toDate.format(formatDate),
              imageBanner: bannerList[0].originFileObj,
              images: fileList?.map((x) => x.originFileObj),
            },
            {
              headers: {
                Authorization: "Bearer " + decryptCookies("token"),
                "Content-Type": "multipart/form-data",
              },
            }
          );
          message.success(data.message);
          dispatch(setFetchTrade(true));
          onCancelModal();
        } catch (error) {
          alert(error?.response?.data?.message);
        } finally {
          setLoading(false);
        }
      })
      .catch(() => {
        return;
      });
  };

  const onCancelModal = () => {
    dispatch(setShowCreate(false));
    form.resetFields();
    setFileList([]);
    setBannerList([]);
  };

  return (
    <Modal
      open={showCreate}
      okText="Submit"
      cancelText="Batal"
      onOk={onSubmit}
      onCancel={onCancelModal}
      okButtonProps={{ loading }}
      title="Form Tambah Event"
      style={{ top: 20 }}
      width={800}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          name="name"
          label="Nama Event"
          rules={[{ required: true, min: 3 }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="description"
          label="Deskripsi"
          rules={[{ required: true, min: 3 }]}
        >
          <TextArea rows={5} />
        </Form.Item>

        <div className="flex flex-col md:flex-row items-center gap-4">
          <Form.Item
            name="fromDate"
            label="Tanggal Mulai"
            rules={[{ required: true, message: "harap diisi" }]}
          >
            <DatePicker format={formatDate} placeholder="Pilih Tanggal" />
          </Form.Item>
          <Form.Item
            name="toDate"
            label="Tanggal Selesai"
            rules={[{ required: true, message: "harap diisi" }]}
          >
            <DatePicker format={formatDate} placeholder="Pilih Tanggal" />
          </Form.Item>
        </div>
        <div className="flex flex-col md:flex-row items-center gap-4">
          <Form.Item
            name="destination"
            label="Lokasi Tujuan"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="CS Whatsapp"
            name="wa"
            rules={[
              { required: true },
              {
                pattern: new RegExp("^([0-9]*)$"),
                message: "Masukkan hanya Nomor",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </div>
        <div className="flex flex-col md:flex-row items-center gap-4">
          <Form.Item name="cost" label="Biaya" rules={[{ required: true }]}>
            <InputNumber
              formatter={(value) =>
                `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")
              }
              parser={(value) => value.replace(/Rp\s?|(\.*)/g, "")}
              style={{ width: "100%" }}
            />
          </Form.Item>
          <Form.Item
            name="costMemberGold"
            label="Biaya Member Gold"
            rules={[{ required: true }]}
          >
            <InputNumber
              formatter={(value) =>
                `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")
              }
              parser={(value) => value.replace(/Rp\s?|(\.*)/g, "")}
              style={{ width: "100%" }}
            />
          </Form.Item>
        </div>
        <Form.Item
          name="showOnHome"
          label="Tampil di Homepage"
          initialValue={true}
          rules={[{ required: true }]}
        >
          <Radio.Group>
            <Radio value={true}>tampil</Radio>
            <Radio value={false}>tidak</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item label="Gambar Banner" rules={[{ required: true }]}>
          <Upload
            accept=".jpg,.jpeg,.png"
            listType="picture-card"
            onPreview={handlePreview}
            onRemove={() => false}
            beforeUpload={(file) => {
              if (file.size <= 3000000) {
                setBannerList([file]);
                return false;
              }
              if (file.size > 3000000) {
                message.error("Maks File 3MB");
              }
            }}
            onChange={(file) => {
              if (file?.file.size > 3000000) return;
              else setBannerList(file?.fileList);
            }}
            fileList={bannerList}
          >
            <div>{bannerList.length === 0 ? uploadButton : "Ganti"}</div>
          </Upload>
        </Form.Item>
        <Form.Item label="Gambar Event">
          <Upload
            accept=".jpg,.jpeg,.png"
            listType="picture-card"
            onPreview={handlePreview}
            beforeUpload={(file) => {
              if (file.size <= 3000000) {
                return false;
              }
              if (file.size > 3000000) {
                message.error("Maks File 3MB");
              }
            }}
            onRemove={(file) => {
              const index = fileList.indexOf(file);
              const newFileList = fileList.slice();
              newFileList.splice(index, 1);
              setFileList(newFileList);
            }}
            onChange={(file) => {
              if (file?.file.size > 3000000) return;
              else setFileList(file?.fileList);
            }}
            fileList={fileList}
          >
            {fileList.length > 6 ? null : uploadButton}
          </Upload>
        </Form.Item>
      </Form>
      <Modal
        open={previewOpen}
        title={previewTitle}
        footer={null}
        width={800}
        style={{ top: 20 }}
        onCancel={() => setPreviewOpen(false)}
      >
        <img alt="example" style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </Modal>
  );
};

export default CreateTradeMission;
