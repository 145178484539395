import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  keyword: "",
  projectsHeadlineId: "",
  showCreateProjectsHeadline: false,
  showEditProjectsHeadline: false,
  fetchProjectsHeadline: false,
};

const projectsSlice = createSlice({
  name: "trade-mission",
  initialState,
  reducers: {
    setKeyword: (state, action) => {
      state.keyword = action.payload;
    },
    setProjectsHeadlineId: (state, action) => {
      state.projectsHeadlineId = action.payload;
    },
    setFetchProjectsHeadline: (state, action) => {
      state.fetchProjectsHeadline = action.payload;
    },
    setShowCreateProjectsHeadline: (state, action) => {
      state.showCreateProjectsHeadline = action.payload;
    },
    setShowEditProjectsHeadline: (state, action) => {
      state.showEditProjectsHeadline = action.payload;
    },
  },
});

export const {
  setKeyword,
  setProjectsHeadlineId,
  setShowEditProjectsHeadline,
  setShowCreateProjectsHeadline,
  setFetchProjectsHeadline,
} = projectsSlice.actions;
export default projectsSlice.reducer;
