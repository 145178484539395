import moment from "moment";
import { CiLocationOn } from "react-icons/ci";

const Headline = ({ data }) => {
  return (
    <div className="relative h-[400px] mt-[60px] after:absolute after:block after:w-full after:h-[400px] after:left-0 after:top-0 after:z-20 after:bg-gradient-to-r after:from-[rgba(0,0,165,0.70)] after:to-[rgba(205,0,220,0.50)] mb-[420px] md:mb-[450px]">
      <img
        src={data?.data?.image_banner}
        alt="trade-mission"
        className="object-cover object-center rounded-sm absolute top-0 left-0 w-full h-full z-10"
      />
      <div className="absolute top-[100%] left-[5%] translate-y-[-50%] z-30 w-[90%] max-w-[1000px] flex flex-col md:flex-row items-center gap-10">
        <div className="bg-white w-full md:w-[70%] lg:w-[50%] xl:w-[45%] pt-6 pb-0 box-border rounded-md shadow-xl border border-gray-300 overflow-hidden">
          <h2 className="text-center text-2xl xl:text-3xl font-semibold my-4">
            {data?.data?.title}
          </h2>
          <div className="py-6 px-8 flex justify-between items-center">
            <div>
              <span className="text-gray-400 font-[300]">First Date</span>
              <p className="text-lg">
                {moment(data?.data?.from_date).format("LL")}
              </p>
              <p className="text-sm text-gray-500">{data?.data?.from_time}</p>
            </div>
            <div>
              <span className="text-gray-400 font-[300]">End Date</span>
              <p className="text-lg">
                {moment(data?.data?.to_date).format("LL")}
              </p>
              <p className="text-sm text-gray-500">{data?.data?.to_time}</p>
            </div>
          </div>
          <div className="p-6 px-8 pb-12">
            <div className="flex flex-wrap justify-between items-center gap-5">
              <div className="text-lg font-semibold">
                <div className="mb-2">
                  <span className="block font-[400] text-gray-500">
                    Cost per-meet
                  </span>
                  <span>
                    Rp
                    {data?.data?.htm
                      ?.toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                  </span>
                </div>
                <div>
                  <span className="block font-[400] text-gray-500">
                    Gold Member
                  </span>
                  Rp
                  {data?.data?.cost_member_gold
                    ?.toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                </div>
              </div>
              <p className="text-lg">
                <CiLocationOn className="text-2xl mb-1 text-gray-500" />
                {data?.data?.location}
              </p>
            </div>
            <span
              className={`${
                moment(new Date()).format("YYYY-MM-DD") >
                moment(data?.data?.from_date).format("YYYY-MM-DD")
                  ? "bg-gray-400"
                  : "bg-blue-500"
              }  block py-4 text-lg font-[500] text-center uppercase mt-10 !rounded-sm text-gray-100`}
            >
              {moment(new Date()).format("YYYY-MM-DD") >
              moment(data?.data?.from_date).format("YYYY-MM-DD")
                ? "Event Closed"
                : "Event Open"}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Headline;
