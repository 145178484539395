import { Empty, Space } from "antd";
import Container from "components/Container";
import { handleLongText } from "components/helper/handleLongText";
import { usePartners } from "hook/users-hook/apiCalls";
import { useNavigate } from "react-router-dom";

const Partners = () => {
  // const [dataTable, setDataTable] = useState({ page: 1, limit: 100 });

  const navigate = useNavigate();

  const { data } = usePartners();

  // const onShowSizeChange = (page, limit) => setDataTable({ page, limit });

  // const handleChange = (page, limit) => setDataTable({ page, limit });

  return (
    <>
      {/* <Headline /> */}
      <Container>
        <div className="pt-8">
          <h1 className="font-bold text-2xl mb-5">All Partners</h1>
          {data?.data?.length === 0 ? (
            <Empty />
          ) : (
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-6 bg-[#F9F8FC] p-5 rounded-md border mb-10">
              {data?.data?.map((partner) => (
                <div
                  key={partner.id}
                  className="max-w-[350px] h-[200px] p-4 bg-white border rounded-md hover:border-gray-400 hover:shadow-sm duration-200 cursor-pointer"
                  onClick={() => navigate(partner.id)}
                >
                  <Space size="middle">
                    <img
                      src={partner.image}
                      alt="profile"
                      className="w-[60px] h-[60px] object-cover object-center rounded-full"
                    />
                    <h2 className="text-[18px] font-bold capitalize">
                      {partner.username}
                    </h2>
                  </Space>
                  <p className="mt-3 text-gray-600">
                    {handleLongText(partner.description, 100)}
                  </p>
                </div>
              ))}
            </div>
          )}
          {/* <div className="flex justify-end my-10">
            <Pagination
              showSizeChanger
              onShowSizeChange={onShowSizeChange}
              defaultCurrent={dataTable.page}
              defaultPageSize={100}
              total={data?.data?.meta?.total}
              onChange={handleChange}
            />
          </div> */}
        </div>
      </Container>
    </>
  );
};

export default Partners;
