import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userId: "",
  showCreate: false,
  showEdit: false,
  showEditPassword: false,
  fetchUser: false,
};

const userMemberSlice = createSlice({
  name: "user-member",
  initialState,
  reducers: {
    setUserId: (state, action) => {
      state.userId = action.payload;
    },
    setShowCreate: (state, action) => {
      state.showCreate = action.payload;
    },
    setShowEdit: (state, action) => {
      state.showEdit = action.payload;
    },
    setShowEditPassword: (state, action) => {
      state.showEditPassword = action.payload;
    },
    setFetchUser: (state, action) => {
      state.fetchUser = action.payload;
    },
  },
});

export const {
  setUserId,
  setShowCreate,
  setShowEdit,
  setShowEditPassword,
  setFetchUser,
} = userMemberSlice.actions;
export default userMemberSlice.reducer;
