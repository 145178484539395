import { useQuery } from "react-query";
import { GetUrl } from "../../api/GetUrl";

export const useProjectsPagination = ({ keyword = "", dataTable }) => {
  return useQuery(
    ["get-projects-pagination", keyword, dataTable.page, dataTable.limit],
    () =>
      GetUrl(
        `/api/project-contents?page=${dataTable.page}&limit=${dataTable.limit}&keyword=${keyword}`
      )
  );
};

export const useProjectsDetail = (id) => {
  return useQuery(
    ["get-projects-detail", id],
    () => GetUrl(`/api/project-contents/${id}`),
    { refetchOnWindowFocus: true, enabled: false }
  );
};

export const useProjectsHeadline = () => {
  return useQuery(
    "get-projects-headline",
    () => GetUrl(`/api/project-headlines?page=1&limit=10`),
    { refetchOnWindowFocus: false }
  );
};
