import { useQuery } from "react-query";
import { GetUrl } from "../../api/GetUrl";

export const useTestimonialPagination = ({ keyword, dataTable }) => {
  return useQuery(
    ["get-testimonial-pagination", keyword, dataTable.page, dataTable.limit],
    () =>
      GetUrl(
        `/api/testimonis?page=${dataTable.page}&limit=${dataTable.limit}&keyword=${keyword}`
      ),
    { refetchOnWindowFocus: true }
  );
};

export const useTestimonialDetail = (id) => {
  return useQuery(
    ["get-testimonial-detail", id],
    () => GetUrl(`/api/testimonis/${id}`),
    { refetchOnWindowFocus: false }
  );
};
