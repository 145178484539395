import { useProjectsPagination } from "hook/projects-hook/apiCalls";
import ProjectsHeadline from "./headline/Headline";
import ProjectsList from "./projects-list";
import { useState } from "react";

const Projects = () => {
  const [dataTable, setDataTable] = useState({ page: 1, limit: 50 });
  // const [debouncedKeyword] = useDebounce(keyword, 2000);

  const { data } = useProjectsPagination({ dataTable });

  const onShowSizeChange = (page, limit) => setDataTable({ page, limit });

  const handleChange = (page, limit) => setDataTable({ page, limit });

  return (
    <div className="mt-[60px]">
      <ProjectsHeadline />
      <ProjectsList
        dataProjects={data}
        handleChange={handleChange}
        onShowSizeChange={onShowSizeChange}
        dataTable={dataTable}
      />
    </div>
  );
};

export default Projects;
