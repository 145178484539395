import { Empty } from "antd";
import Container from "components/Container";
import DividerComponent from "components/Divider";
import { handleLongText } from "components/helper/handleLongText";
import { useProductsPagination } from "hook/products-hook/apiCalls";
import { useUsersDetail } from "hook/users-hook/apiCalls";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

const DetailPartners = () => {
  const { partners_id } = useParams();
  const navigate = useNavigate();

  const { data, refetch } = useUsersDetail(partners_id);

  const { data: dataProducts } = useProductsPagination({
    dataTable: { page: 1, limit: 1000 },
    keyword: "",
    userId: partners_id,
  });

  useEffect(() => {
    if (!partners_id) return;
    refetch();
  }, [partners_id, refetch]);

  const handleWa = (param) => {
    if (param[0] === "0") return "62" + param.slice(1, param.length);
    else if (param[0] === "6" && param.charAt(1) === "2") return param;
    else return "62" + param;
  };

  return (
    <Container className="mb-20">
      <div>
        <div className="rounded-lg mb-20 p-4">
          <div className="flex flex-col lg:flex-row">
            <div className="w-full md:w-2/3 order-2 lg:order-1">
              <h2 className="text-teal-500 md:text-xl">Partner</h2>
              <h2 className="font-bold capitalize mb-1 text-3xl md:text-4xl">
                {data?.data?.username}
              </h2>
              <p className="mt-4">{data?.data?.description}</p>
              {data?.data?.phone && (
                <a
                  href={`https://wa.me/${handleWa(
                    data?.data?.phone
                  )}?text=Halo!`}
                  target="_blank"
                  rel="noreferrer"
                  className="bg-teal-500 block max-w-[200px] py-1 text-white text-center mt-10 rounded-md"
                >
                  Chat Partner
                </a>
              )}

              <div className="mt-6">
                <h3 className="font-bold">More info: </h3>
                <p className="">Company Name: {data?.data?.company_name}</p>
                <p>
                  Link Company:{" "}
                  <a
                    href={data?.data?.url}
                    target="_blank"
                    rel="noreferrer"
                    className="text-blue-600"
                  >
                    {data?.data?.url}
                  </a>
                </p>
              </div>
            </div>
            <div className="w-full md:w-1/3 flex justify-center order-1 lg:order-2">
              <img
                src={data?.data?.image}
                alt="profile"
                className="w-[350px] h-[350px] object-cover object-center rounded-lg"
              />
            </div>
          </div>
        </div>

        <DividerComponent
          orientationMargin={0}
          className="font-bold !border-gray-200"
        >
          Products
        </DividerComponent>
        {dataProducts?.data?.data.length === 0 ? (
          <Empty />
        ) : (
          <div className="flex flex-wrap gap-6 mt-6">
            {dataProducts?.data?.data?.map((product) => (
              <div
                key={product.id}
                className="w-[300px] pb-4 border-2 rounded-lg hover:border-gray-300 hover:shadow-[0_0_15px_rgba(0,0,0,0.15)] hover:cursor-pointer duration-200"
                onClick={() => navigate(product.id)}
              >
                <img
                  src={product.banner}
                  alt="product"
                  className="w-full h-[250px] object-cover object-center rounded-lg"
                />

                <div className="pt-4 px-3">
                  <h3 className="font-bold mb-1">{product.name}</h3>
                  <p className="mb-1 text-gray-600">
                    {handleLongText(product.description, 50)}
                  </p>
                  <p className=" text-gray-500 text-[14px]">
                    Rp
                    {product.price
                      ? product.price
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                      : ""}
                  </p>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </Container>
  );
};

export default DetailPartners;
