import { Button, Form, Input, message } from "antd";
import axios from "axios";
import { encryptCookies } from "components/helper/cookieshelper";
import { encryptValue } from "components/helper/crypto";
import { setAuthContent, setToken } from "features/auth/auth-slice";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./login.css";
import Cookies from "js-cookie";

const { REACT_APP_HOST_API } = process.env;

const Login = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const { REACT_APP_HOST_API: url } = process.env;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogin = async () => {
    form
      .validateFields()
      .then(async (values) => {
        setLoading(true);

        try {
          const { data } = await axios.post(url + "/api/login", values);

          encryptCookies("token", data.token.token);
          encryptCookies("dataUser", JSON.stringify(data.data));
          dispatch(setToken(encryptValue(data.token.token)));
          message.success(data.message, 2);
          if (Cookies.get("isNavigate")) navigate(-1);
          else navigate("/");
        } catch (error) {
          if (error?.response?.data?.errors) {
            if (error?.response?.data?.errors[0]?.message.includes("exist")) {
              alert("Email Belum Terdaftar");
              return;
            } else {
              alert(error?.response?.data?.errors[0]?.message);
            }
          } else if (error?.response?.data?.error) {
            // alert("Harap cek kembali email atau password anda");
            alert(error?.response?.data?.error);
            return;
          } else if (error?.response?.data?.message) {
            alert(error?.response?.data?.message);
            return;
          } else {
            alert(error.message);
          }
        } finally {
          setLoading(false);
        }
      })
      .catch(() => {
        return;
      });
  };

  // google login
  const handleCallbackResponse = useCallback(
    async (response) => {
      setLoading(true);
      try {
        const { data } = await axios.post(
          REACT_APP_HOST_API + "/api/auth/google",
          { cred: response.credential }
        );

        encryptCookies("token", data.token.token);
        encryptCookies("dataUser", JSON.stringify(data.data));
        dispatch(setToken(encryptValue(data.token.token)));
        message.success(data.message, 2);
        if (Cookies.get("isNavigate")) navigate(-1);
        else navigate("/");
      } catch (error) {
        if (error?.response?.data?.message) {
          alert(error?.response?.data?.message);
          return;
        }
        alert(error.message);
      } finally {
        setLoading(false);
      }
    },
    [navigate, dispatch]
  );

  const divRef = useRef(null);
  const [isFetch, setIsFetch] = useState(false);

  useEffect(() => {
    // render google button
    if (divRef.current) {
      setIsFetch(true);
      window.google?.accounts?.id?.initialize({
        client_id:
          "242455265953-lf7im4edikdusr8m15k4n7r0vm2lumg6.apps.googleusercontent.com",
        callback: handleCallbackResponse,
      });

      window.google?.accounts?.id?.renderButton(divRef.current, {
        theme: "outline",
        size: "large",
      });
    }
    // eslint-disable-next-line
  }, [handleCallbackResponse, divRef.current, isFetch]);

  return (
    <div className="max-w-[500px]">
      <Form layout="vertical" form={form}>
        <Form.Item
          name="email"
          label="Email"
          rules={[{ required: true, message: "Harap masukkan email" }]}
        >
          <Input size="large" />
        </Form.Item>
        <Form.Item
          name="password"
          label="Password"
          rules={[{ required: true, message: "Harap masukkan password" }]}
        >
          <Input.Password size="large" />
        </Form.Item>

        <Button
          type="primary"
          htmlType="submit"
          onClick={handleLogin}
          loading={loading}
          size="large"
          block
        >
          Login
        </Button>
      </Form>
      <div id="signInDiv" ref={divRef} className="my-3 w-full" />
      <p className="mt-4 text-gray-600 font-[300] text-right">
        Don't have an account?{" "}
        <span
          className="text-blue-700 underline cursor-pointer"
          onClick={() => dispatch(setAuthContent("sign-up"))}
        >
          Sign Up
        </span>
      </p>
    </div>
  );
};

export default Login;
