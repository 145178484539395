import { Button, Modal } from "antd";
import { HeadlinesContext } from "context/HeadlinesContext";
import { useHeadlinesDetail } from "hook/headlines-hook/apiCalls";
import { useContext, useEffect } from "react";

const DetailHeadlines = () => {
  const { headlinesId, showDetail, setShowDetail } =
    useContext(HeadlinesContext);

  const { data, refetch } = useHeadlinesDetail(headlinesId);

  useEffect(() => {
    if (!headlinesId) return;
    refetch();
  }, [refetch, headlinesId]);

  const onCancel = () => setShowDetail(false);

  return (
    <Modal
      open={showDetail}
      okText="Submit"
      cancelText="Batal"
      onCancel={onCancel}
      footer={[
        <Button key={1} type="primary" onClick={onCancel}>
          Ok
        </Button>,
      ]}
    >
      <img
        src={data?.data?.image}
        alt="headline"
        className="w-[300px] h-[200px] object-cover object-center"
      />

      <h1 className="text-xl font-semibold my-2">{data?.data?.title}</h1>
      <a
        href={data?.data?.url}
        target="_blank"
        rel="noreferrer"
        className="text-blue-500 mb-1 block"
      >
        {data?.data?.url}
      </a>
      <p>{data?.data?.description}</p>
    </Modal>
  );
};

export default DetailHeadlines;
