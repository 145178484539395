import { BiHomeAlt2 } from "react-icons/bi";
import { FaBuffer, FaRegNewspaper } from "react-icons/fa";
import { HiUsers } from "react-icons/hi";
import { IoMailUnreadOutline } from "react-icons/io5";
import { LuGalleryHorizontal } from "react-icons/lu";
import { MdEventNote } from "react-icons/md";
import { RxDashboard } from "react-icons/rx";
import { VscFileSubmodule } from "react-icons/vsc";

export const menuItems = [
  { key: "/", icon: <BiHomeAlt2 />, label: "Home" },
  { key: "/admin", icon: <RxDashboard />, label: "Dashboard" },
  {
    key: "/admin/headline",
    icon: <LuGalleryHorizontal />,
    label: "Home Headline",
  },
  {
    key: "/admin/corporate-users",
    icon: <FaBuffer />,
    label: "Corporates",
  },
  {
    key: "member",
    icon: <HiUsers />,
    label: "Member",
    children: [
      { key: "/admin/member", label: "Member Type" },
      { key: "/admin/member/users", label: "Users" },
      { key: "/admin/member/submission", label: "Gold Submission" },
    ],
  },
  {
    key: "events",
    icon: <MdEventNote />,
    label: "Events",
    children: [
      { key: "/admin/trade-mission", label: "Trade mission" },
      { key: "/admin/business-matching", label: "Business Matching" },
      { key: "/admin/free-learnings", label: "Free Learnings" },
      { key: "/admin/paid-learnings", label: "Paid Learnings" },
    ],
  },
  {
    key: "/admin/projects",
    icon: <VscFileSubmodule />,
    label: "Projects",
  },
  {
    key: "/admin/newsinfo",
    icon: <FaRegNewspaper />,
    label: "News & Info",
  },
  {
    key: "/admin/testimonial",
    icon: <IoMailUnreadOutline />,
    label: "Testimonial",
  },
];
