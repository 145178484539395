import AdminMasterBanks from "./banks";
import InfoBiznids from "./info/InfoBiznids";
import SocialMedia from "./social-media/SocialMedia";

const DashboardAdmin = () => {
  return (
    <div>
      <InfoBiznids />
      <SocialMedia />
      <AdminMasterBanks />
    </div>
  );
};

export default DashboardAdmin;
