import { Input, Popconfirm, Space, Table, Tag } from "antd";
import { DeleteApi } from "api/DeleteApi";
import TableHeader from "components/TableHeader";
import { handleLongText } from "components/helper/handleLongText";
import {
  setFetchProducts,
  setProductsId,
  setShowCreateProducts,
  setShowDetailProducts,
  setShowEditProducts,
} from "features/products/products-slice";
import { useProductsPagination } from "hook/products-hook/apiCalls";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CreateProducts from "./Create";
import EditProducts from "./Edit";
import DetailProducts from "./Detail";
import Cookies from "js-cookie";
import { decryptCookies } from "components/helper/cookieshelper";

const CorporateMasterProducts = () => {
  const [keyword, setKeyword] = useState("");
  const [dataTable, setDataTable] = useState({ limit: 20, page: 1 });

  const dispatch = useDispatch();
  const { fetchProducts } = useSelector((state) => state.products);

  const userId =
    Cookies.get("dataUser") && JSON.parse(decryptCookies("dataUser")).id;

  const { data, isLoading, refetch } = useProductsPagination({
    dataTable,
    keyword,
    userId,
  });

  const dataSource = data?.data?.data?.map((x, i) => ({
    ...x,
    key: x.id,
    index: i + 1,
  }));

  useEffect(() => {
    refetch();

    return () => dispatch(setFetchProducts(false));
  }, [refetch, fetchProducts, dispatch]);

  const columns = [
    { title: "No", dataIndex: "index", width: 50 },
    { title: "Nama", dataIndex: "name" },
    {
      title: "Harga",
      dataIndex: "price",
      render: (price) =>
        price ? price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") : "",
    },
    {
      title: "Deskripsi",
      dataIndex: "description",
      render: (desc) => handleLongText(desc, 50),
    },
    {
      title: "Aksi",
      dataIndex: "id",
      align: "center",
      render: (id) => (
        <Space>
          <Popconfirm
            title="Yakin ingin menghapus?"
            okText="Hapus"
            cancelText="Batal"
            onConfirm={() =>
              DeleteApi({ url: "/api/products/", dataId: id, refetch })
            }
          >
            <Tag color="magenta" style={{ cursor: "pointer" }}>
              Hapus
            </Tag>
          </Popconfirm>
          <Tag
            color="orange"
            style={{ cursor: "pointer" }}
            onClick={() => {
              dispatch(setShowEditProducts(true));
              dispatch(setProductsId(id));
            }}
          >
            Ubah
          </Tag>
          <Tag
            color="blue"
            style={{ cursor: "pointer" }}
            onClick={() => {
              dispatch(setProductsId(id));
              dispatch(setShowDetailProducts(true));
            }}
          >
            Detail
          </Tag>
        </Space>
      ),
    },
  ];

  const pagination = {
    current: dataTable.page,
    pageSize: dataTable.limit,
    defaultPageSize: dataTable.limit,
    total: data?.data.meta.total,
    showSizeChanger: true,
    pageSizeOptions: [10, 20, 50, 100],
    onChange: (curr, size) => {
      setDataTable((value) => {
        return {
          ...value,
          page: curr,
          limit: size,
        };
      });
    },
  };
  return (
    <div>
      <TableHeader
        title="Products"
        onClick={() => dispatch(setShowCreateProducts(true))}
      />
      <div className="table-filter">
        <div className="filter">
          <Input
            placeholder="cari nama"
            onChange={({ target: { value } }) => setKeyword(value)}
          />
        </div>
      </div>
      <Table
        dataSource={dataSource}
        columns={columns}
        size="small"
        loading={isLoading}
        pagination={pagination}
        scroll={{ x: 800 }}
      />
      <CreateProducts />
      <EditProducts />
      <DetailProducts />
    </div>
  );
};

export default CorporateMasterProducts;
