import { useFreeLearningsDetail } from "hook/learnings-hook/apiCalls";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

const LinkFreeLearnings = () => {
  const { free_id } = useParams();

  const { data, refetch } = useFreeLearningsDetail(free_id);

  useEffect(() => {
    refetch();
  }, [refetch]);

  return (
    <div className="w-full h-screen flex items-center justify-center">
      <div className="max-w-[600px] border border-gray-300 p-5 md:p-10 rounded-md">
        <h1 className="text-3xl md:text-4xl font-[500] mb-5">
          Selamat! anda telah berhasil bergabung di Kelas
          <br />
          <span className="bg-yellow-200 inline-block">
            {data?.data?.title}
          </span>
          <br />
          Silahkan simpan link dibawah untuk mengikuti Kelas tersebut
        </h1>
        <p className="text-2xl text-blue-600 ">{data?.data?.link}</p>
      </div>
    </div>
  );
};

export default LinkFreeLearnings;
