import { GetUrl } from "api/GetUrl";
import { useQuery } from "react-query";

export const useBanksPagination = ({ keyword = "", dataTable }) => {
  return useQuery(
    ["get-banks-pagination", keyword, dataTable.page, dataTable.limit],
    () =>
      GetUrl(
        `/api/rekenings?page=${dataTable.page}&limit=${dataTable.limit}&keyword=${keyword}`
      ),
    { refetchOnWindowFocus: false }
  );
};

export const useBanksDetail = (id) => {
  return useQuery(
    ["get-banks-detail", id],
    () => GetUrl(`/api/rekenings/${id}`),
    { refetchOnWindowFocus: false, enabled: false }
  );
};
