import { PlusOutlined } from "@ant-design/icons";
import { Form, Input, Modal, Upload, message } from "antd";
import axios from "axios";
import { decryptCookies } from "components/helper/cookieshelper";
import {
  setFetchMatchingHeadline,
  setShowEditHeadline,
} from "features/business-matching/business-matching-slice";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const { TextArea } = Input;

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const { REACT_APP_HOST_API } = process.env;

const EditMatchingHeadline = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [newFileList, setNewFileList] = useState([]);

  const dispatch = useDispatch();
  const { showEditHeadline, matchingHeadlineId } = useSelector(
    (state) => state.businessMatching
  );

  const fetchDetail = useCallback(async () => {
    try {
      const { data } = await axios.get(
        REACT_APP_HOST_API +
          `/api/business-matching-headlines/${matchingHeadlineId}`,
        { headers: { Authorization: "Bearer " + decryptCookies("token") } }
      );

      if (data?.data?.banner) {
        setFileList([
          {
            uid: "-1",
            name: "headlines",
            status: "done",
            url: data.data.banner,
          },
        ]);
      }

      form.setFieldsValue({
        title: data?.data?.title,
        description: data?.data?.description,
      });
    } catch (error) {
      alert(error?.response?.data?.message);
    }
  }, [form, matchingHeadlineId]);

  useEffect(() => {
    if (showEditHeadline) fetchDetail();
  }, [showEditHeadline, fetchDetail]);

  const onCancelModal = () => {
    form.resetFields();
    setFileList([]);
    dispatch(setShowEditHeadline(false));
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const onSubmit = async () => {
    form
      .validateFields()
      .then(async (values) => {
        setLoading(true);
        try {
          const { data } = await axios.put(
            REACT_APP_HOST_API +
              "/api/business-matching-headlines/" +
              matchingHeadlineId,

            { ...values, banner: newFileList ? newFileList[0] : fileList[0] },
            {
              headers: {
                Authorization: "Bearer " + decryptCookies("token"),
                "Content-Type": "multipart/form-data",
              },
            }
          );
          message.success(data.message);
          dispatch(setFetchMatchingHeadline(true));
          onCancelModal();
        } catch (error) {
          alert(error?.response?.data?.message);
        } finally {
          setLoading(false);
        }
      })
      .catch(() => {
        return;
      });
  };

  return (
    <Modal
      open={showEditHeadline}
      okText="Submit"
      cancelText="Batal"
      onOk={onSubmit}
      onCancel={onCancelModal}
      okButtonProps={{ loading }}
      width={600}
    >
      <Form form={form} layout="vertical">
        <Form.Item label="Gambar">
          <Upload
            fileList={fileList}
            accept=".jpg,.jpeg,.png"
            listType="picture-card"
            onPreview={handlePreview}
            onRemove={() => false}
            onChange={(file) => {
              if (file?.file.size > 3000000) return;
              else setFileList(file.fileList);
            }}
            beforeUpload={(file) => {
              if (file.size <= 3000000) {
                setFileList([file]);
                setNewFileList([file]);
                return false;
              }
              if (file.size > 3000000) {
                message.error("Maks File 3MB");
              }
            }}
          >
            {fileList?.length > 1
              ? null
              : fileList.length === 0
              ? uploadButton
              : "Ganti"}
          </Upload>
        </Form.Item>
        <Form.Item name="title" label="Title" rules={[{ min: 3 }]}>
          <Input />
        </Form.Item>
        <Form.Item name="description" label="Description" rules={[{ min: 3 }]}>
          <TextArea rows={5} />
        </Form.Item>
      </Form>
      <Modal
        open={previewOpen}
        title={previewTitle}
        footer={null}
        width={800}
        style={{ top: 20 }}
        onCancel={() => setPreviewOpen(false)}
      >
        <img alt="example" style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </Modal>
  );
};

export default EditMatchingHeadline;
