import { GetUrl } from "api/GetUrl";
import { useQuery } from "react-query";

export const useContactUsPagination = ({ keyword = "", dataTable }) => {
  return useQuery(
    ["get-contactUs-pagination", keyword, dataTable.page, dataTable.limit],
    () =>
      GetUrl(
        `/api/contact-us?page=${dataTable.page}&limit=${dataTable.limit}&keyword=${keyword}`
      ),
    { refetchOnWindowFocus: false }
  );
};

export const useContactUsDetail = (id) => {
  return useQuery(
    ["get-contactUs-detail", id],
    () => GetUrl(`/api/contact-us/${id}`),
    { refetchOnWindowFocus: false, enabled: false }
  );
};
