import { useQuery } from "react-query";
import { GetUrl } from "../../api/GetUrl";

export const useBusinessMatchingPagination = ({
  keyword = "",
  date,
  dataTable = { page: 1, limit: 10 },
  corporateId = "",
  userId = "",
  upComing,
}) => {
  return useQuery(
    [
      "get-business-matching-pagination",
      keyword,
      date?.from,
      date?.to,
      dataTable.page,
      dataTable.limit,
      corporateId,
      userId,
      upComing,
    ],
    () =>
      GetUrl(
        `/api/event-offlines?page=${dataTable.page}&limit=${dataTable.limit}&keyword=${keyword}&fromDate=${date?.from}&toDate=${date?.to}&corporateId=${corporateId}&userId=${userId}&upComing=${upComing}`
      ),
    { refetchOnWindowFocus: false }
  );
};

export const useBusinessMatchingDetail = ({ id, corporateId, userId }) => {
  return useQuery(
    ["get-business-matching-detail", id, corporateId, userId],
    () =>
      GetUrl(
        `/api/event-offlines/${id}?corporateId=${
          corporateId ? corporateId : ""
        }&userId=${userId ? userId : ""}`
      ),
    { refetchOnWindowFocus: true }
  );
};

export const useBusinessMatchingContributorsPagination = ({
  eventId,
  dataTable,
  token,
}) => {
  return useQuery(
    [
      "get-business-matching-corporate-pagination",
      eventId,
      dataTable.limit,
      dataTable.page,
      token,
    ],
    () =>
      GetUrl(
        `/api/event-offline/corporates?page=${dataTable.page}&limit=${dataTable.limit}&eventOfflineId=${eventId}`
      ),
    { refetchOnWindowFocus: false }
  );
};

export const useBusinessMatchingBookings = ({
  corporate_event_id,
  dataTable = { page: 1, limit: 20 },
  keyword = "",
}) => {
  return useQuery(
    [
      "get-business-matching-corporate-pagination",
      corporate_event_id,
      dataTable.limit,
      dataTable.page,
    ],
    () =>
      GetUrl(
        `/api/event-offline/corporate/users?page=${dataTable.page}&limit=${dataTable.limit}&keyword=${keyword}&corporateEventOfflineId=${corporate_event_id}`
      ),
    { refetchOnWindowFocus: true }
  );
};

export const useMatchingHeadline = () => {
  return useQuery(
    "get-matching-headline",
    () => GetUrl(`/api/business-matching-headlines?page=1&limit=10`),
    { refetchOnWindowFocus: false }
  );
};
