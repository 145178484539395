import { Button, Card, Tabs } from 'antd';
import Section from 'components/Section';
import { decryptCookies } from 'components/helper/cookieshelper';
import Cookies from 'js-cookie';
import moment from 'moment';
import { useState } from 'react';
import FormBooking from './FormBooking';
import { useNavigate } from 'react-router-dom';

const { Meta } = Card;

const Tenants = ({ data, onSuccess }) => {
	const [idCorporate, setIdCorporate] = useState('');
	const [showCreate, setShowCreate] = useState(false);

	const navigate = useNavigate();

	const role =
		Cookies.get('dataUser') && JSON.parse(decryptCookies('dataUser')).role;
	const userId =
		Cookies.get('dataUser') && JSON.parse(decryptCookies('dataUser')).id;
	// const token = Cookies.get("token") && decryptCookies("token").token;

	// get every 30 minutes from event from to time
	const startDate = new Date(data?.data?.from_date);
	const endDate = new Date(data?.data?.to_date);

	// Array to store dates
	const dates = [];

	// Function to add days to a date
	function addDays(date, days) {
		const result = new Date(date);
		result.setDate(result.getDate() + days);
		return result;
	}

	// Loop through each day and add it to the array
	let currentDate = startDate;
	while (currentDate <= endDate) {
		dates.push(moment(new Date(currentDate)).format('YYYY-MM-DD'));
		currentDate = addDays(currentDate, 1);
	}

	function generateTimeIntervals(start, end, interval) {
		const result = [];
		let currentTime = new Date(start);

		while (currentTime < end) {
			result.push(currentTime.toLocaleTimeString([], { hour12: false }));
			currentTime.setMinutes(currentTime.getMinutes() + interval);
		}

		return result;
	}

	const startTime = new Date(`2024-02-11T${data?.data?.from_time}`);
	const endTime = new Date(`2024-02-11T${data?.data?.to_time}`);
	const intervalMinutes = 30;

	const intervals = generateTimeIntervals(startTime, endTime, intervalMinutes);

	const onCancel = () => {
		setShowCreate(false);
		setIdCorporate('');
	};

	const items = [
		{
			key: '1',
			label: <p className='text-xl'>Tenants</p>,
			children: (
				<div className='flex items-center gap-5 flex-wrap p-6'>
					{data?.data?.corporateEventOffline?.map((x) => (
						<div key={x.id}>
							<Card
								className='w-[300px] border-gray-300 overflow-hidden'
								cover={
									<img
										className='w-[150px] h-[200px] object-contain object-center'
										alt='tenant'
										src={x?.corporate?.image}
									/>
								}>
								<Meta title={x.corporate?.username} />

								<div className='flex gap-4 flex-wrap'>
									{moment(new Date()).format('YYYY-MM-DD') >
									moment(data?.data?.from_date).format('YYYY-MM-DD') ? (
										<Button
											style={{ marginTop: 20 }}
											disabled
											block>
											Event Closed
										</Button>
									) : (
										<>
											<Button
												style={{ marginTop: 20 }}
												disabled={role === 'corporate_user'}
												onClick={() => {
													navigate(`/partners/${x.corporate.id}`);
												}}
												className={`border-2 border-teal-500 ${
													role === 'corporate_user'
														? ''
														: 'hover:!border-teal-500 hover:bg-teal-500 hover:!text-white'
												}`}>
												Show detail
											</Button>
											<Button
												style={{ marginTop: 20 }}
												disabled={role === 'corporate_user'}
												onClick={() => {
													if (role === 'admin_user') {
														alert('admin tidak bisa book meet!');
														return;
													}
													if (!userId) {
														navigate('/user-auth');
														Cookies.set('isNavigate', true);
													} else {
														setShowCreate(true);
														setIdCorporate(x.id);
													}
												}}
												className={`bg-teal-500 border-2 border-teal-500  text-white ${
													role === 'corporate_user'
														? ''
														: 'hover:!border-teal-500 hover:!text-white'
												}`}>
												Book Meet
											</Button>
										</>
									)}
								</div>
							</Card>
						</div>
					))}
				</div>
			),
		},
	];

	return (
		<>
			<Section>
				<div>
					<Tabs
						defaultActiveKey='1'
						items={items}
					/>
				</div>
			</Section>
			<FormBooking
				onCancel={onCancel}
				show={showCreate}
				idCorporate={idCorporate}
				onSuccess={onSuccess}
				intervals={intervals}
				dates={dates}
			/>
		</>
	);
};

export default Tenants;
