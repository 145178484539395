import Container from "components/Container";
import { useBusinessMatchingDetail } from "hook/business-matching-hook/apiCalls";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import Galleries from "./Galleries";
import Headline from "./Headline";
import Overview from "./Overview";
import Tenants from "./Tenants";

const DetailBusinessMatching = () => {
  const { matching_id } = useParams();

  const { data, refetch } = useBusinessMatchingDetail({
    id: matching_id,
  });

  useEffect(() => {
    refetch();
  }, [refetch]);

  return (
    <div>
      <Headline data={data} />
      <Container>
        <Overview data={data} />
        <Tenants data={data} onSuccess={refetch} />
        {/* <EventMap data={data} /> */}
        <Galleries data={data} />
      </Container>
    </div>
  );
};

export default DetailBusinessMatching;
