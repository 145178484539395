import { Form, Input, Modal, Radio } from "antd";
import axios from "axios";
import { useContext, useState } from "react";
import { decryptCookies } from "../../../components/helper/cookieshelper";
import { CorporateContext } from "../../../context/CorporateContext";

const CreateCorporate = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const { showCreate, setShowCreate, setFetchCorporate } =
    useContext(CorporateContext);

  const { REACT_APP_HOST_API } = process.env;

  const onSubmit = async () => {
    form
      .validateFields()
      .then(async (values) => {
        setLoading(true);
        try {
          const { data } = await axios.post(
            REACT_APP_HOST_API + "/api/register",
            { ...values, role: "corporate_user" },
            {
              headers: {
                Authorization: "Bearer " + decryptCookies("token"),
                "Content-Type": "multipart/form-data",
              },
            }
          );
          alert(data.message);
          setFetchCorporate(true);
          onCancelModal();
        } catch (error) {
          alert(error.message);
        } finally {
          setLoading(false);
        }
      })
      .catch(() => {
        return;
      });
  };

  const onCancelModal = () => {
    setShowCreate(false);
    form.resetFields();
  };

  return (
    <Modal
      open={showCreate}
      okText="Submit"
      cancelText="Batal"
      onOk={onSubmit}
      onCancel={onCancelModal}
      okButtonProps={{ loading }}
      width={800}
    >
      <Form form={form} layout="vertical">
        <div className="block md:flex justify-between gap-6">
          <Form.Item
            label="Username"
            name="username"
            rules={[
              { required: true, message: "Please fill this input!" },
              { min: 3, message: "Input minimum 3 character" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Company Name"
            name="companyName"
            rules={[{ min: 3, message: "Input minimum 3 character" }]}
          >
            <Input />
          </Form.Item>
        </div>
        <Form.Item
          label="Email"
          name="email"
          rules={[
            { required: true, message: "Please fill this input!" },
            { type: "email", message: "Please input a valid email" },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Phone"
          name="phone"
          rules={[
            { required: true, message: "Please fill this input!" },
            { min: 7, message: "Input minimum 7 character" },
            {
              pattern: new RegExp("^([0-9]*)$"),
              message: "Input only number",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <div className="flex flex-col md:flex-row">
          <Form.Item
            name="showOnPartners"
            label="Tampil di Partner"
            rules={[{ required: true }]}
          >
            <Radio.Group>
              <Radio value={true}>Tampil</Radio>
              <Radio value={false}>Tidak</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            name="showOnHome"
            label="Tampil di Homepage"
            rules={[{ required: true }]}
          >
            <Radio.Group>
              <Radio value={true}>Tampil</Radio>
              <Radio value={false}>Tidak</Radio>
            </Radio.Group>
          </Form.Item>
        </div>

        <div className="block md:flex justify-between gap-6">
          <Form.Item
            label="Password"
            name="password"
            hasFeedback
            rules={[{ required: true, min: 6 }]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            label="Password Confirmation"
            name="password_confirmation"
            hasFeedback
            rules={[
              { required: true },
              { min: 6 },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }

                  return Promise.reject(new Error("Password must be same!"));
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
        </div>
      </Form>
      {/* <Modal
        open={previewOpen}
        title={previewTitle}
        footer={null}
        width={800}
        style={{ top: 20 }}
        onCancel={() => setPreviewOpen(false)}
      >
        <img alt="example" style={{ width: "100%" }} src={previewImage} />
      </Modal> */}
    </Modal>
  );
};

export default CreateCorporate;
