import Container from "components/Container";
import { useBanksPagination } from "hook/banks-hook/apiCalls";
import React from "react";

const BankAccounts = () => {
  const { data } = useBanksPagination({
    dataTable: { page: 1, limit: 1000000 },
  });

  return (
    <div className="py-10 lg:py-20">
      <Container>
        <h1 className="text-2xl lg:text-3xl font-bold">Bank Accounts</h1>
        {data?.data?.data?.map((bank) => (
          <div
            key={bank.id}
            className="w-[300px] my-5 rounded-md overflow-hidden border border-gray-300"
          >
            <h2 className="bg-gray-200 p-2 font-bold">{bank.name}</h2>
            <div className="w-full flex flex-col gap-2 justify-between py-4 px-2">
              <p>{bank.no_rek}</p>
              <p>{bank.an}</p>
            </div>
          </div>
        ))}
      </Container>
    </div>
  );
};

export default BankAccounts;
