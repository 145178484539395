import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  keyword: "",
  date: { from: "", to: "" },

  freeLearningsId: "",
  showCreateFreeLearnings: false,
  showEditFreeLearnings: false,
  fetchFreeLearnings: false,

  paidLearningsId: "",
  showCreatePaidLearnings: false,
  showEditPaidLearnings: false,
  fetchPaidLearnings: false,

  learningsHeadlineId: "",
  showCreateLearningsHeadline: false,
  showEditLearningsHeadline: false,
  fetchLearningsHeadline: false,
};

const learningsSlice = createSlice({
  name: "learnings",
  initialState,
  reducers: {
    setFreeLearningsId: (state, action) => {
      state.freeLearningsId = action.payload;
    },
    setShowCreateFreeLearnings: (state, action) => {
      state.showCreateFreeLearnings = action.payload;
    },
    setShowEditFreeLearnings: (state, action) => {
      state.showEditFreeLearnings = action.payload;
    },
    setFetchFreeLearnings: (state, action) => {
      state.fetchFreeLearnings = action.payload;
    },

    setPaidLearningsId: (state, action) => {
      state.paidLearningsId = action.payload;
    },
    setShowCreatePaidLearnings: (state, action) => {
      state.showCreatePaidLearnings = action.payload;
    },
    setShowEditPaidLearnings: (state, action) => {
      state.showEditPaidLearnings = action.payload;
    },
    setFetchPaidLearnings: (state, action) => {
      state.fetchPaidLearnings = action.payload;
    },

    setLearningsHeadlineId: (state, action) => {
      state.learningsHeadlineId = action.payload;
    },
    setShowCreateLearningsHeadline: (state, action) => {
      state.showCreateLearningsHeadline = action.payload;
    },
    setShowEditLearningsHeadline: (state, action) => {
      state.showEditLearningsHeadline = action.payload;
    },
    setFetchLearningsHeadline: (state, action) => {
      state.fetchLearningsHeadline = action.payload;
    },
    // setFromDate: (state, action) => {
    //   state.date.from = action.payload;
    // },
    // setToDate: (state, action) => {
    //   state.date.to = action.payload;
    // },
    setKeyword: (state, action) => {
      state.keyword = action.payload;
    },
  },
});

export const {
  setFreeLearningsId,
  setFetchFreeLearnings,
  setShowEditFreeLearnings,
  setShowCreateFreeLearnings,
  setFetchLearningsHeadline,
  setKeyword,
  setShowCreateLearningsHeadline,
  setShowEditLearningsHeadline,
  setLearningsHeadlineId,
  setFetchPaidLearnings,
  setPaidLearningsId,
  setShowCreatePaidLearnings,
  setShowEditPaidLearnings,
} = learningsSlice.actions;
export default learningsSlice.reducer;
