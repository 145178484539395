import { Badge, Image, Popconfirm, Space, Table, Tag, message } from "antd";
import { DeleteApi } from "api/DeleteApi";
import axios from "axios";
import TableHeader from "components/TableHeader";
import { decryptCookies } from "components/helper/cookieshelper";
import { formatRupiah } from "components/helper/formatRupiah";
import { useBusinessMatchingBookings } from "hook/business-matching-hook/apiCalls";
import moment from "moment";
import { Fragment, useState } from "react";
import { useParams } from "react-router-dom";

const { REACT_APP_HOST_API } = process.env;

const MasterCorporateBookings = () => {
  const [imgUrl, setImgUrl] = useState("");
  const [visible, setVisible] = useState(false);
  const [dataTable, setDataTable] = useState({ limit: 20, page: 1 });

  const { corporate_matching_id: id } = useParams();

  const { data, isLoading, refetch } = useBusinessMatchingBookings({
    dataTable,
    corporate_event_id: id,
  });

  const dataSource = data?.data?.data?.map((x, i) => ({
    ...x,
    key: x.id,
    index: i + 1,
    username: x?.user?.username,
    email: x?.user?.email,
    phone: x?.user?.phone,
    time: x?.from_time + " - " + x?.to_time,
  }));

  const columns = [
    {
      title: "No",
      dataIndex: "index",
      width: 50,
    },
    { title: "Nama", dataIndex: "username" },
    { title: "Email", dataIndex: "email" },
    { title: "Phone", dataIndex: "phone" },
    {
      title: "Tanggal",
      dataIndex: "date",
      render: (x) => moment(x).format("LL"),
    },
    { title: "Jam Booking", dataIndex: "time" },
    {
      title: "Status",
      dataIndex: "status",
      render: (x) => (
        <Badge
          status={
            x === "waiting"
              ? "processing"
              : x === "approve"
              ? "success"
              : "error"
          }
          text={
            x === "waiting"
              ? "menunggu"
              : x === "approve"
              ? "diterima"
              : "ditolak"
          }
        />
      ),
    },
    {
      title: "Jumlah Bayar",
      dataIndex: "code_unique",
      render: (x) => (x ? "Rp" + formatRupiah(x) : ""),
    },
    {
      title: "Bukti Bayar",
      dataIndex: "image",
      render: (x) => (
        <Fragment>
          {x ? (
            <Tag
              color="blue"
              onClick={() => {
                setImgUrl(x);
                setVisible(true);
              }}
              className="cursor-pointer"
            >
              Lihat
            </Tag>
          ) : (
            "-"
          )}
        </Fragment>
      ),
    },
    {
      title: "Aksi",
      dataIndex: "id",
      align: "center",
      render: (_, record) => (
        <Space>
          <Popconfirm
            title="Yakin ingin menghapus?"
            okText="Hapus"
            cancelText="Batal"
            onConfirm={() =>
              DeleteApi({
                url: "/api/event-offline/corporate/users/",
                dataId: record.id,
                refetch,
              })
            }
          >
            <Tag color="magenta" style={{ cursor: "pointer" }}>
              Hapus
            </Tag>
          </Popconfirm>
          <Popconfirm
            title="Anda yakin ingin menolak booking ini?"
            okText="Tolak"
            cancelText="Batal"
            onConfirm={() => onUpdate("rejected", record.id)}
          >
            <Tag
              color="magenta"
              style={{ cursor: "pointer" }}
              hidden={record.status === "rejected"}
            >
              Tolak
            </Tag>
          </Popconfirm>
          <Popconfirm
            title="Anda yakin ingin menerima booking ini?"
            okText="Terima"
            cancelText="Batal"
            onConfirm={() => onUpdate("approve", record.id)}
          >
            <Tag
              color="green"
              style={{ cursor: "pointer" }}
              hidden={record.status === "approve"}
            >
              Terima
            </Tag>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const onUpdate = async (status, id) => {
    try {
      const { data } = await axios.put(
        REACT_APP_HOST_API + "/api/event-offline/corporate/users/" + id,
        { status },
        { headers: { Authorization: "Bearer " + decryptCookies("token") } }
      );

      message.success(data.message);
      refetch();
    } catch (error) {
      alert(error.message);
    }
  };

  const pagination = {
    current: dataTable.page,
    pageSize: dataTable.limit,
    defaultPageSize: dataTable.limit,
    total: data?.data?.meta?.total,
    showSizeChanger: true,
    pageSizeOptions: [10, 20, 50, 100],
    onChange: (curr, size) => {
      setDataTable((value) => {
        return {
          ...value,
          page: curr,
          limit: size,
        };
      });
    },
  };

  return (
    <div>
      <TableHeader title="Bookings" addBtn={false} />
      <Table
        dataSource={dataSource}
        columns={columns}
        size="small"
        loading={isLoading}
        pagination={pagination}
        scroll={{ x: 1200 }}
      />
      <Image
        width={200}
        style={{
          display: "none",
        }}
        src={imgUrl}
        preview={{
          visible,
          src: imgUrl,
          onVisibleChange: (value) => setVisible(value),
        }}
      />
    </div>
  );
};

export default MasterCorporateBookings;
