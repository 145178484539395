import { Image, Input, Popconfirm, Space, Table, Tag, message } from "antd";
import axios from "axios";
import TableHeader from "components/TableHeader";
import { decryptCookies } from "components/helper/cookieshelper";
import { Fragment, useCallback, useEffect, useState } from "react";
import Edit from "./Edit";
import moment from "moment";

const { REACT_APP_HOST_API: url } = process.env;

const GoldSubmission = () => {
  const [keyword, setKeyword] = useState("");
  const [dataTable, setDataTable] = useState({ page: 1, limit: 10 });
  const [dataSubmission, setDataSubmission] = useState([]);

  const [imgUrl, setImgUrl] = useState("");
  const [visible, setVisible] = useState(false);

  const [dataId, setDataId] = useState("");
  const [showEdit, setShowEdit] = useState(false);

  const fetchSubmission = useCallback(async () => {
    try {
      const { data } = await axios.get(
        url +
          `/api/upgrade-members?page=${dataTable.page}&limit=${dataTable.limit}&keyword=${keyword}`,
        { headers: { Authorization: "Bearer " + decryptCookies("token") } }
      );
      setDataSubmission(data);
    } catch (error) {
      alert("Error get data" + error.message);
    }
  }, [dataTable.page, dataTable.limit, keyword]);

  useEffect(() => {
    fetchSubmission();
  }, [fetchSubmission]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        fetchSubmission();
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [fetchSubmission]);

  const dataSource = dataSubmission?.data?.data?.map((x, i) => ({
    ...x,
    index: i + 1,
    key: x.id,
    username: x?.user?.username,
    phone: x?.user?.phone,
    expire: x?.user?.expire_at,
  }));

  const columns = [
    { title: "No", dataIndex: "index", width: 50 },
    { title: "Nama", dataIndex: "username" },
    { title: "Phone", dataIndex: "phone" },
    { title: "Status", dataIndex: "status" },
    {
      title: "Aktiv Sampai",
      dataIndex: "expire",
      render: (x) => moment(x).format("LL"),
    },
    {
      title: "Bukti Bayar",
      render: (_, record) => (
        <Fragment>
          {record.image ? (
            <Tag
              color="blue"
              onClick={() => {
                setImgUrl(record.image);
                setVisible(true);
              }}
              className="cursor-pointer"
            >
              Lihat
            </Tag>
          ) : (
            "-"
          )}
        </Fragment>
      ),
    },
    {
      title: "Aksi",
      dataIndex: "id",
      render: (_, record) => (
        <Space>
          <Tag
            color="orange"
            className="cursor-pointer"
            hidden={record.status === "waiting"}
            onClick={() => {
              if (record.status === "waiting") return;
              else {
                setShowEdit(true);
                setDataId(record.id);
              }
            }}
          >
            Ubah
          </Tag>
          <Popconfirm
            okText="Ya"
            title="Yakin ingin menerima permintaan ini?"
            onConfirm={() => {
              if (record.status === "accepted") return;
              else handleAccept(record.id);
            }}
            disabled={record.status === "accepted"}
          >
            <Tag
              color="blue"
              className="cursor-pointer"
              hidden={record.status === "accepted"}
            >
              Accept
            </Tag>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const pagination = {
    current: dataTable.page,
    pageSize: dataTable.limit,
    defaultPageSize: dataTable.limit,
    total: dataSubmission?.data?.meta?.total,
    showSizeChanger: true,
    pageSizeOptions: [10, 20, 50, 100],
    onChange: (curr, size) => {
      setDataTable((value) => {
        return {
          ...value,
          page: curr,
          limit: size,
        };
      });
    },
  };

  const handleAccept = async (id) => {
    try {
      const { data } = await axios.put(
        url + `/api/upgrade-members/${id}`,
        { status: "accepted" },
        { headers: { Authorization: "Bearer " + decryptCookies("token") } }
      );
      message.success(data.message);
      fetchSubmission();
    } catch (error) {
      alert("Error: " + error.message);
    }
  };

  const onCancel = () => setShowEdit(false);

  const onUpdate = () => {
    setShowEdit(false);
    fetchSubmission();
  };

  return (
    <div>
      <TableHeader title="Daftar Pengajuan Gold Membership" addBtn={false} />
      <div className="table-filter">
        <div className="filter">
          <Input
            placeholder="cari nama"
            onChange={({ target: { value } }) => setKeyword(value)}
          />
        </div>
      </div>
      <Table
        columns={columns}
        dataSource={dataSource}
        size="small"
        pagination={pagination}
        scroll={{ x: 800 }}
      />
      <Edit
        id={dataId}
        show={showEdit}
        onCancel={onCancel}
        onUpdate={onUpdate}
      />

      <Image
        width={200}
        style={{ display: "none" }}
        src={imgUrl}
        preview={{
          visible,
          src: imgUrl,
          onVisibleChange: (value) => {
            setVisible(value);
          },
        }}
      />
    </div>
  );
};

export default GoldSubmission;
