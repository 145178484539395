import { PlusOutlined } from "@ant-design/icons";
import { RichTextEditor } from "@mantine/rte";
import { Button, DatePicker, Form, Input, Modal, Upload, message } from "antd";
import axios from "axios";
import { decryptCookies } from "components/helper/cookieshelper";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const date = "YYYY-MM-DD";
const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const { REACT_APP_HOST_API } = process.env;

const CreateProjects = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [bannerList, setBannerList] = useState([]);
  const [value, setValue] = useState("");
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");

  const navigate = useNavigate();
  const html2json = require("html2json").html2json;

  const onCancelModal = () => {
    form.resetFields();
    setFileList([]);
    setBannerList([]);
    navigate(-1);
  };

  const onSubmit = async () => {
    const values = await form.validateFields();

    setLoading(true);
    try {
      const { data } = await axios.post(
        REACT_APP_HOST_API + `/api/project-contents`,
        {
          ...values,
          content: html2json(value),
          date: values?.date?.format(date),
          banner: bannerList[0].originFileObj,
          images: fileList?.map((x) => x.originFileObj),
        },
        {
          headers: {
            Authorization: "Bearer " + decryptCookies("token"),
            "Content-Type": "multipart/form-data",
          },
        }
      );
      message.success(data?.message);
      onCancelModal();
    } catch (error) {
      alert(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  // image upload
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  return (
    <>
      <Form form={form} layout="vertical">
        <Form.Item label="Banner" rules={[{ required: true }]}>
          <Upload
            accept=".jpg,.jpeg,.png"
            listType="picture-card"
            onPreview={handlePreview}
            onRemove={() => false}
            beforeUpload={(file) => {
              if (file.size <= 3000000) {
                setBannerList([file]);
                return false;
              }
              if (file.size > 3000000) {
                message.error("Maks File 3MB");
              }
            }}
            onChange={(file) => {
              if (file?.file.size > 3000000) return;
              else setBannerList(file?.fileList);
            }}
            fileList={bannerList}
          >
            <div>{bannerList.length === 0 ? uploadButton : "Ganti"}</div>
          </Upload>
        </Form.Item>
        <Form.Item label="Gambar Projects">
          <Upload
            accept=".jpg,.jpeg,.png"
            listType="picture-card"
            onPreview={handlePreview}
            beforeUpload={(file) => {
              if (file.size <= 3000000) {
                return false;
              }
              if (file.size > 3000000) {
                message.error("Maks File 2MB");
              }
            }}
            onRemove={(file) => {
              const index = fileList.indexOf(file);
              const newFileList = fileList.slice();
              newFileList.splice(index, 1);
              setFileList(newFileList);
            }}
            onChange={(file) => {
              if (file?.file.size > 3000000) return;
              else setFileList(file?.fileList);
            }}
            fileList={fileList}
          >
            {fileList.length > 6 ? null : uploadButton}
          </Upload>
        </Form.Item>
        <Form.Item
          name="title"
          label="Nama Project"
          rules={[{ required: true, min: 3 }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="responsibility"
          label="Penanggung Jawab/ Ketua Project"
          rules={[{ required: true, min: 3 }]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="date" label="Tanggal" rules={[{ required: true }]}>
          <DatePicker format="YYYY-MM-DD" />
        </Form.Item>
        <Form.Item
          name="content"
          label="Content"
          rules={[{ required: true, min: 3 }]}
        >
          <RichTextEditor
            value={value}
            onChange={setValue}
            id="rte"
            controls={[
              ["bold", "italic", "underline", "link"],
              ["h1", "h2", "h3"],
              ["unorderedList", "orderedList"],
              ["alignLeft", "alignCenter", "alignRight"],
            ]}
          />
        </Form.Item>

        <div className="my-4 flex justify-end gap-4">
          <Button onClick={onCancelModal}>Batal</Button>
          <Button type="primary" onClick={onSubmit} loading={loading}>
            Submit
          </Button>
        </div>
      </Form>
      <Modal
        open={previewOpen}
        title={previewTitle}
        footer={null}
        width={800}
        style={{ top: 20 }}
        onCancel={() => setPreviewOpen(false)}
      >
        <img alt="example" style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </>
  );
};

export default CreateProjects;
