import { DatePicker, Input, Popconfirm, Space, Table, Tag } from "antd";
import { DeleteApi } from "api/DeleteApi";
import TableHeader from "components/TableHeader";
import {
  setFetchTrade,
  setShowCreate,
  setShowEdit,
  setTradeId,
} from "features/trade-mission/trade-mission-slice";
import { useTradeMissionPagination } from "hook/trade-mission-hook/apiCalls";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CreateTradeMission from "./Create";
import EditTradeMission from "./Edit";

const MasterTradeMission = () => {
  const [keyword, setKeyword] = useState("");
  const [date, setDate] = useState({ from: "", to: "" });
  const [dataTable, setDataTable] = useState({ limit: 20, page: 1 });

  const dispatch = useDispatch();

  const { fetchTrade } = useSelector((state) => state.tradeMission);

  const { data, isLoading, refetch } = useTradeMissionPagination({
    date,
    keyword,
    dataTable,
  });

  const dataSource = data?.data?.data?.map((x, i) => ({
    ...x,
    key: x.id,
    index: i + 1,
  }));

  useEffect(() => {
    refetch();

    return () => dispatch(setFetchTrade(false));
  }, [refetch, dispatch, fetchTrade]);

  const columns = [
    { title: "No", dataIndex: "index", width: 50 },
    { title: "Nama", dataIndex: "name" },
    { title: "Lokasi Tujuan", dataIndex: "destination" },
    { title: "Tanggal Awal", dataIndex: "from_date" },
    { title: "Tanggal Akhir", dataIndex: "to_date" },
    {
      title: "Biaya",
      dataIndex: "cost",
      render: (x) =>
        x ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") : "Free",
    },
    {
      title: "Tampil di Home",
      dataIndex: "show_on_home",
      render: (x) => (x === true ? "Tampil" : "Tidak"),
    },
    {
      title: "Aksi",
      dataIndex: "id",
      align: "center",
      render: (id) => (
        <Space>
          <Popconfirm
            title="Yakin ingin menghapus?"
            okText="Hapus"
            cancelText="Batal"
            onConfirm={() =>
              DeleteApi({ url: "/api/trade-missions/", dataId: id, refetch })
            }
          >
            <Tag color="magenta" style={{ cursor: "pointer" }}>
              Hapus
            </Tag>
          </Popconfirm>
          <Tag
            color="orange"
            style={{ cursor: "pointer" }}
            onClick={() => {
              dispatch(setShowEdit(true));
              dispatch(setTradeId(id));
            }}
          >
            Ubah
          </Tag>
        </Space>
      ),
    },
  ];

  const pagination = {
    current: dataTable.page,
    pageSize: dataTable.limit,
    defaultPageSize: dataTable.limit,
    total: data?.data.meta.total,
    showSizeChanger: true,
    pageSizeOptions: [10, 20, 50, 100],
    onChange: (curr, size) => {
      setDataTable((value) => {
        return {
          ...value,
          page: curr,
          limit: size,
        };
      });
    },
  };
  return (
    <div>
      <TableHeader
        title="Trade Mission"
        onClick={() => dispatch(setShowCreate(true))}
      />
      <div className="table-filter">
        <div className="filter">
          <Input
            placeholder="cari nama"
            onChange={({ target: { value } }) => setKeyword(value)}
          />
        </div>
        <div className="filter">
          <DatePicker
            placeholder="Tanggal Awal"
            style={{ width: "100%" }}
            onChange={(e) => {
              if (e === null) setDate((curr) => ({ ...curr, from: "" }));
              else
                setDate((curr) => ({
                  ...curr,
                  from: e.format("YYYY-MM-DD"),
                }));
            }}
          />
        </div>
        <div className="filter">
          <DatePicker
            placeholder="Tanggal Akhir"
            style={{ width: "100%" }}
            onChange={(e) => {
              if (e === null) setDate((curr) => ({ ...curr, to: "" }));
              else
                setDate((curr) => ({
                  ...curr,
                  to: e.format("YYYY-MM-DD"),
                }));
            }}
          />
        </div>
      </div>
      <Table
        dataSource={dataSource}
        columns={columns}
        size="small"
        loading={isLoading}
        pagination={pagination}
        scroll={{ x: 800 }}
      />

      <CreateTradeMission />
      <EditTradeMission />
    </div>
  );
};

export default MasterTradeMission;
