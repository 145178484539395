import { PlusOutlined } from "@ant-design/icons";
import {
  Form,
  Input,
  InputNumber,
  Modal,
  Skeleton,
  Upload,
  message,
} from "antd";
import axios from "axios";
import { decryptCookies } from "components/helper/cookieshelper";
import {
  setFetchProducts,
  setShowEditProducts,
} from "features/products/products-slice";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
const { TextArea } = Input;

const { REACT_APP_HOST_API: url } = process.env;

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const EditProducts = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [newData, setNewData] = useState({});
  const [fileList, setFileList] = useState([]);
  const [bannerList, setBannerList] = useState([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [newFileList, setNewFileList] = useState([]);
  const [newBannerList, setNewBannerList] = useState([]);
  const [deletedImages, setDeletedImages] = useState([]);

  const dispatch = useDispatch();
  const { productsId, showEditProducts } = useSelector(
    (state) => state.products
  );

  const fetchDetail = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await axios.get(url + `/api/products/${productsId}`, {
        headers: { Authorization: "Bearer " + decryptCookies("token") },
      });

      if (data?.data?.banner) {
        const image = data.data.banner;
        const newImage = {
          uid: 1,
          name: image.split("products")[1].split("?")[0].substring(1),
          status: "done",
          url: image,
        };

        setBannerList([newImage]);
      }

      if (data?.data?.images) {
        const newImages = data.data.images.map((x, i) => {
          return {
            uid: i + 1,
            name: x.split("products")[1].split("?")[0].substring(1),
            status: "done",
            url: x,
          };
        });

        setFileList(newImages);
      }

      form.setFieldsValue({
        name: data?.data?.name,
        description: data?.data?.description,
        price: data?.data?.price,
        link: data?.data?.link,
      });
    } catch (error) {
      alert(error?.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  }, [form, productsId]);

  useEffect(() => {
    if (showEditProducts) fetchDetail();

    return () => setNewData({});
  }, [showEditProducts, fetchDetail]);

  const onCancelModal = () => {
    form.resetFields();
    setFileList([]);
    setNewFileList([]);
    setBannerList([]);
    setNewBannerList([]);
    setDeletedImages([]);
    dispatch(setShowEditProducts(false));
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const handleChange = (param) =>
    (newData[param.target.name] = param.target.value);

  const onSubmit = async () => {
    form
      .validateFields()
      .then(async () => {
        if (
          Object.keys(newData).length === 0 &&
          newFileList.length === 0 &&
          deletedImages.length === 0 &&
          newBannerList.length === 0
        ) {
          alert("Tidak ada yang dirubah");
          return;
        }

        setLoading(true);
        try {
          const { data } = await axios.put(
            url + "/api/products/" + productsId,
            newFileList.length === 0 &&
              deletedImages === 0 &&
              newBannerList === 0
              ? { ...newData }
              : {
                  ...newData,
                  images: newFileList?.map((x) => x),
                  banner: newBannerList[0],
                  deleteImages:
                    deletedImages.length > 0 && deletedImages.join(","),
                },
            {
              headers: {
                Authorization: "Bearer " + decryptCookies("token"),
                "Content-Type": "multipart/form-data",
              },
            }
          );
          message.success(data.message);
          dispatch(setFetchProducts(true));
          onCancelModal();
        } catch (error) {
          alert(error?.response?.data?.message);
        } finally {
          setLoading(false);
        }
      })
      .catch(() => {
        return;
      });
  };

  return (
    <Modal
      open={showEditProducts}
      okText="Submit"
      cancelText="Batal"
      onOk={onSubmit}
      onCancel={onCancelModal}
      okButtonProps={{ loading }}
      style={{ top: 20 }}
      width={800}
    >
      {isLoading ? (
        new Array(5).fill(null).map((_, i) => <Skeleton key={i} active />)
      ) : (
        <Form form={form} layout="vertical">
          <Form.Item name="name" label="Nama Produk" rules={[{ min: 3 }]}>
            <Input name="name" onChange={handleChange} />
          </Form.Item>
          <Form.Item
            name="description"
            label="Deskripsi Produk"
            rules={[{ min: 3 }]}
          >
            <TextArea name="description" rows={5} onChange={handleChange} />
          </Form.Item>

          <div className="flex flex-col md:flex-row items-center gap-4">
            <Form.Item name="price" label="Harga" rules={[{ required: true }]}>
              <InputNumber
                formatter={(value) =>
                  `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                }
                parser={(value) => value.replace(/Rp\s?|(\.*)/g, "")}
                style={{ width: "100%" }}
                onChange={(e) => (newData.price = e)}
              />
            </Form.Item>
            <Form.Item
              name="link"
              label="Link Product (jika ada)"
              rules={[{ min: 3 }]}
            >
              <Input name="link" onChange={handleChange} />
            </Form.Item>
          </div>

          <Form.Item label="Gambar Thumbnail">
            <Upload
              fileList={bannerList}
              accept=".jpg,.jpeg,.png"
              listType="picture-card"
              onPreview={handlePreview}
              onRemove={() => false}
              onChange={(file) => {
                if (file?.file.size > 3000000) return;
                else setBannerList(file.fileList);
              }}
              beforeUpload={(file) => {
                if (file.size <= 3000000) {
                  setBannerList([file]);
                  setNewBannerList([file]);
                  return false;
                }
                if (file.size > 3000000) {
                  message.error("Maks File 3MB");
                }
              }}
            >
              {bannerList?.length > 1
                ? null
                : bannerList.length === 0
                ? uploadButton
                : "Ganti"}
            </Upload>
          </Form.Item>
          <Form.Item label="Gambar Produk">
            <Upload
              fileList={fileList}
              accept=".jpg,.jpeg,.png"
              listType="picture-card"
              onPreview={handlePreview}
              onChange={(file) => {
                if (file?.file.size > 3000000) return;
                else setFileList(file.fileList);
              }}
              onRemove={(file) => {
                setDeletedImages([...deletedImages, file?.name]);
                const index = fileList.indexOf(file);
                const newFileList = fileList.slice();
                newFileList.splice(index, 1);
                setFileList(newFileList);
              }}
              beforeUpload={(file) => {
                if (file.size <= 3000000) {
                  setFileList([...fileList, file]);
                  setNewFileList([...newFileList, file]);
                  return false;
                }
                if (file.size > 3000000) {
                  message.error("Maks File 2MB");
                }
              }}
            >
              {fileList?.length > 6 ? null : uploadButton}
            </Upload>
          </Form.Item>
        </Form>
      )}
      <Modal
        open={previewOpen}
        title={previewTitle}
        footer={null}
        width={800}
        style={{ top: 20 }}
        onCancel={() => setPreviewOpen(false)}
      >
        <img alt="example" style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </Modal>
  );
};

export default EditProducts;
