import { DatePicker, Input, Popconfirm, Space, Table, Tag } from "antd";
import { DeleteApi } from "api/DeleteApi";
import TableHeader from "components/TableHeader";
import {
  setFetchFreeLearnings,
  setFreeLearningsId,
  setShowCreateFreeLearnings,
  setShowEditFreeLearnings,
} from "features/learnings/learnings-slice";
import { useFreeLearningsPagination } from "hook/learnings-hook/apiCalls";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CreateFreeLearnings from "./Create";
import EditFreeLearnings from "./Edit";

const MasterFreeLearnings = () => {
  const [keyword, setKeyword] = useState("");
  const [date, setDate] = useState({ from: "", to: "" });
  const [dataTable, setDataTable] = useState({ limit: 20, page: 1 });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { fetchFreeLearnings } = useSelector((state) => state.learnings);

  const { data, isLoading, refetch } = useFreeLearningsPagination({
    date,
    keyword,
    dataTable,
  });

  const dataSource = data?.data?.data?.map((x, i) => ({
    ...x,
    key: x.id,
    index: i + 1,
  }));

  useEffect(() => {
    refetch();

    return () => dispatch(setFetchFreeLearnings(false));
  }, [refetch, dispatch, fetchFreeLearnings]);

  const columns = [
    { title: "No", dataIndex: "index", width: 50 },
    { title: "Nama", dataIndex: "title" },
    { title: "Speaker", dataIndex: "speaker" },
    {
      title: "Tanggal Awal",
      dataIndex: "from_date",
      render: (x) => moment(x).format("LL - HH:mm"),
    },
    {
      title: "Tanggal Akhir",
      dataIndex: "to_date",
      render: (x) => moment(x).format("LL - HH:mm"),
    },
    { title: "Jumlah Peserta", dataIndex: "total_guest" },
    {
      title: "Tampil di Home",
      dataIndex: "show_on_home",
      render: (x) => (x === true ? "Tampil" : "Tidak"),
    },
    {
      title: "Aksi",
      dataIndex: "id",
      align: "center",
      render: (id) => (
        <Space>
          <Popconfirm
            title="Yakin ingin menghapus?"
            okText="Hapus"
            cancelText="Batal"
            onConfirm={() =>
              DeleteApi({
                url: "/api/biznids-learning/free/",
                dataId: id,
                refetch,
              })
            }
          >
            <Tag color="magenta" style={{ cursor: "pointer" }}>
              Hapus
            </Tag>
          </Popconfirm>
          <Tag
            color="orange"
            style={{ cursor: "pointer" }}
            onClick={() => {
              dispatch(setShowEditFreeLearnings(true));
              dispatch(setFreeLearningsId(id));
            }}
          >
            Ubah
          </Tag>
          <Tag
            color="blue"
            style={{ cursor: "pointer" }}
            onClick={() => navigate(`participants/${id}`)}
          >
            Peserta
          </Tag>
        </Space>
      ),
    },
  ];

  const pagination = {
    current: dataTable.page,
    pageSize: dataTable.limit,
    defaultPageSize: dataTable.limit,
    total: data?.data.meta.total,
    showSizeChanger: true,
    pageSizeOptions: [10, 20, 50, 100],
    onChange: (curr, size) => {
      setDataTable((value) => {
        return {
          ...value,
          page: curr,
          limit: size,
        };
      });
    },
  };
  return (
    <div>
      <TableHeader
        title="Free Learnings"
        onClick={() => dispatch(setShowCreateFreeLearnings(true))}
      />
      <div className="table-filter">
        <div className="filter">
          <Input
            placeholder="cari nama"
            onChange={({ target: { value } }) => setKeyword(value)}
          />
        </div>
        <div className="filter">
          <DatePicker
            placeholder="Tanggal Awal"
            style={{ width: "100%" }}
            onChange={(e) => {
              if (e === null) setDate((curr) => ({ ...curr, from: "" }));
              else
                setDate((curr) => ({
                  ...curr,
                  from: e.format("YYYY-MM-DD"),
                }));
            }}
          />
        </div>
        <div className="filter">
          <DatePicker
            placeholder="Tanggal Akhir"
            style={{ width: "100%" }}
            onChange={(e) => {
              if (e === null) setDate((curr) => ({ ...curr, to: "" }));
              else
                setDate((curr) => ({
                  ...curr,
                  to: e.format("YYYY-MM-DD"),
                }));
            }}
          />
        </div>
      </div>
      <Table
        dataSource={dataSource}
        columns={columns}
        size="small"
        loading={isLoading}
        pagination={pagination}
        scroll={{ x: 1000 }}
        tableLayout="auto"
      />

      <CreateFreeLearnings />
      <EditFreeLearnings />
    </div>
  );
};

export default MasterFreeLearnings;
