import { GetUrl } from "api/GetUrl";
import { useQuery } from "react-query";

export const useInfoBiznids = () => {
  return useQuery(
    "get-info-biznids-pagination",
    () => GetUrl(`/api/contact-us`),
    { refetchOnWindowFocus: false }
  );
};

export const useInfoBiznidsDetail = (id) => {
  return useQuery(
    ["get-info-biznids-detail", id],
    () => GetUrl(`/api/contact-us/${id}`),
    { refetchOnWindowFocus: true, enabled: false }
  );
};

export const useSocialMediaPagination = ({ dataTable, keyword = "" }) => {
  return useQuery(
    ["get-socialMedia-pagination", dataTable.page, dataTable.limit, keyword],
    () =>
      GetUrl(
        `/api/social-medias?page=${dataTable.page}&limit=${dataTable.limit}&keyword=${keyword}`
      ),
    { refetchOnWindowFocus: false }
  );
};

export const useSocialMediaDetail = (id) => {
  return useQuery(
    ["get-socialMedia-detail", id],
    () => GetUrl(`/api/social-medias/${id}`),
    { refetchOnWindowFocus: true, enabled: false }
  );
};
