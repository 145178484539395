import React from "react";
import { Divider } from "antd";

const DividerComponent = ({
  children,
  className,
  orientation,
  orientationMargin,
}) => {
  return (
    <Divider
      orientation={orientation || "left"}
      orientationMargin={orientationMargin}
      className={`${className}`}
    >
      <div className={`text-xl font-[400] ${className}`}>{children}</div>
    </Divider>
  );
};

export default DividerComponent;
