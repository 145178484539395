import {
  useProjectsDetail,
  useProjectsPagination,
} from "hook/projects-hook/apiCalls";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import parser from "html-react-parser";
import { json2html } from "html2json";
import { Image } from "antd";
import "./detailProjects.css";
import Container from "components/Container";

const DetailProjectsPage = () => {
  const { projects_id } = useParams();
  const navigate = useNavigate();

  const { data: dataDetail, refetch } = useProjectsDetail(projects_id);
  const { data } = useProjectsPagination({ dataTable: { page: 1, limit: 5 } });

  useEffect(() => {
    if (!projects_id) return;
    refetch();
  }, [refetch, projects_id]);

  return (
    <Container>
      <div className="mt-[60px] pt-6 mb-32 flex flex-col lg:flex-row gap-4">
        <div className="w-full lg:w-[75%] mt-6">
          <h1 className="text-xl md:text-3xl font-semibold">
            {dataDetail?.data?.title}
          </h1>
          <span className="block my-5 border-b" />
          <img
            src={dataDetail?.data?.banner}
            alt="banner"
            className="max-w-full w-[700px] h-[400px] object-cover object-center"
          />
          <div className="mt-3">
            <p>Penanggung jawab: {dataDetail?.data?.responsibility}</p>
            <p className="text-gray-600 text-sm">{dataDetail?.data?.date}</p>
          </div>

          <div className="detail-projects mt-4">
            {dataDetail?.data?.content
              ? parser(json2html(dataDetail?.data?.content))
              : "loading..."}
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 gap-5 mt-4">
            {dataDetail?.data?.images?.map((image, index) => (
              <Image
                key={index}
                src={image}
                alt="projects"
                className="max-w-full h-[250px] object-cover object-center border"
              />
            ))}
          </div>
        </div>
        <div className="w-full lg:w-[25%] flex flex-row lg:flex-col items-end mt-20">
          <div>
            <h3 className="text-xl font-semibold mb-4">Other projects</h3>
            <div className="flex flex-row gap-6 lg:flex-col flex-wrap">
              {data?.data?.data?.map((project) => (
                <div key={project.id} className="md:max-w-[200px] mb-2 lg:mb-6">
                  <img
                    src={project.banner}
                    alt="banner"
                    className="rounded-md"
                  />
                  <h3
                    className="font-semibold mt-1 hover:text-blue-500 duration-200 cursor-pointer"
                    onClick={() => navigate(`/projects/${project.id}`)}
                  >
                    {project.title}
                  </h3>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default DetailProjectsPage;
