import Container from "components/Container";
import Section from "components/Section";

const Overview = ({ data }) => {
  return (
    <Section>
      <Container>
        <div className="pb-16 flex flex-col md:flex-row justify-between items-center border-b">
          <div className="w-full md:w-1/2 mb-4 md:mb-0">
            <h2 className="text-2xl md:text-3xl mb-4">Event Overview</h2>
            <p className="text-gray-500 font-[300] w-full md:w-[80%]">
              {data?.data?.description}
            </p>
          </div>
          <div className="w-full md:w-1/2 md:flex justify-center">
            <img
              src={
                data?.data?.images
                  ? data?.data?.images[0]
                  : data?.data?.image_banner
              }
              alt="event-overview"
              className="w-full md:w-[70%] object-cover object-center rounded-md"
            />
          </div>
        </div>
      </Container>
    </Section>
  );
};

export default Overview;
