import { BiHomeAlt2 } from "react-icons/bi";
import { MdEventNote } from "react-icons/md";
import { RxDashboard } from "react-icons/rx";

export const itemsCorporate = [
  {
    key: "/",
    icon: <BiHomeAlt2 />,
    label: "Home",
  },
  {
    key: "/corporate",
    icon: <RxDashboard />,
    label: "Dashboard",
  },
  {
    key: "/corporate/products",
    icon: <RxDashboard />,
    label: "Products",
  },
  {
    key: "/corporate/business-matching",
    icon: <MdEventNote />,
    label: "Business Matching",
  },
  // {
  //   key: "/corporate/learnings",
  //   icon: <MdEventNote />,
  //   label: "Biznids Learnings",
  // },
];
