import { PlusOutlined } from "@ant-design/icons";
import {
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Radio,
  Skeleton,
  TimePicker,
  Upload,
  message,
} from "antd";
import axios from "axios";
import { decryptCookies } from "components/helper/cookieshelper";
import dayjs from "dayjs";
import {
  setFetchMatching,
  setShowEdit,
} from "features/business-matching/business-matching-slice";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
const { TextArea } = Input;

const dateFormat = "YYYY-MM-DD";
const timeFormat = "HH:mm:ss";
const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const { REACT_APP_HOST_API } = process.env;

const EditBusinessMatching = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [newData, setNewData] = useState({});
  const [fileList, setFileList] = useState([]);
  const [bannerList, setBannerList] = useState([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [newFileList, setNewFileList] = useState([]);
  const [newBannerList, setNewBannerList] = useState([]);
  const [deletedImages, setDeletedImages] = useState([]);

  const dispatch = useDispatch();
  const { matchingId, showEdit } = useSelector(
    (state) => state.businessMatching
  );

  const fetchDetail = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await axios.get(
        REACT_APP_HOST_API + `/api/event-offlines/${matchingId}`,
        { headers: { Authorization: "Bearer " + decryptCookies("token") } }
      );

      if (data?.data?.image_banner) {
        const image = data.data.image_banner;
        const newImage = {
          uid: 1,
          name: image.split("events")[1].split("?")[0].substring(1),
          status: "done",
          url: image,
        };

        setBannerList([newImage]);
      }

      if (data?.data?.images) {
        const newImages = data.data.images.map((x, i) => {
          return {
            uid: i + 1,
            name: x.split("events")[1].split("?")[0].substring(1),
            status: "done",
            url: x,
          };
        });

        setFileList(newImages);
      }

      form.setFieldsValue({
        title: data?.data?.title,
        description: data?.data?.description,
        showOnHome: data?.data?.show_on_home,
        location: data?.data?.location,
        speaker: data?.data?.speaker,
        htm: data?.data?.htm,
        costMemberGold: data?.data?.cost_member_gold,
        fromDate: dayjs(moment(data?.data?.from_date).format(dateFormat)),
        toDate: dayjs(moment(data?.data?.to_date).format(dateFormat)),
        fromTime: dayjs(data?.data?.from_time, timeFormat),
        toTime: dayjs(data?.data?.to_time, timeFormat),
      });
    } catch (error) {
      alert(error?.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  }, [form, matchingId]);

  useEffect(() => {
    if (showEdit) fetchDetail();

    return () => setNewData({});
  }, [showEdit, fetchDetail]);

  const onCancelModal = () => {
    form.resetFields();
    setFileList([]);
    setNewFileList([]);
    setBannerList([]);
    setNewBannerList([]);
    setDeletedImages([]);
    dispatch(setShowEdit(false));
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const handleChange = (param) =>
    (newData[param.target.name] = param.target.value);

  const onSubmit = async () => {
    form
      .validateFields()
      .then(async (values) => {
        if (
          Object.keys(newData).length === 0 &&
          newFileList.length === 0 &&
          deletedImages.length === 0 &&
          newBannerList.length === 0
        ) {
          alert("Tidak ada yang dirubah");
          return;
        }

        const fromTimeArray = values.fromTime.format(timeFormat).split(":");
        fromTimeArray[2] = "00";
        newData.fromTime = fromTimeArray.join(":");

        const toTimeArray = values.toTime.format(timeFormat).split(":");
        toTimeArray[2] = "00";
        newData.toTime = toTimeArray.join(":");

        setLoading(true);
        try {
          const { data } = await axios.put(
            REACT_APP_HOST_API + "/api/event-offlines/" + matchingId,
            newFileList.length === 0 &&
              deletedImages === 0 &&
              newBannerList === 0
              ? { ...newData }
              : {
                  ...newData,
                  images: newFileList?.map((x) => x),
                  imageBanner: newBannerList[0],
                  deleteImages:
                    deletedImages.length > 0 && deletedImages.join(","),
                },
            {
              headers: {
                Authorization: "Bearer " + decryptCookies("token"),
                "Content-Type": "multipart/form-data",
              },
            }
          );
          message.success(data.message);
          dispatch(setFetchMatching(true));
          onCancelModal();
        } catch (error) {
          alert(error?.response?.data?.message);
        } finally {
          setLoading(false);
        }
      })
      .catch(() => {
        return;
      });
  };

  return (
    <Modal
      open={showEdit}
      okText="Submit"
      cancelText="Batal"
      onOk={onSubmit}
      onCancel={onCancelModal}
      okButtonProps={{ loading }}
      width={800}
    >
      {isLoading ? (
        new Array(5).fill(null).map((_, i) => <Skeleton key={i} active />)
      ) : (
        <Form form={form} layout="vertical">
          <Form.Item name="title" label="Nama Event" rules={[{ min: 3 }]}>
            <Input name="title" onChange={handleChange} />
          </Form.Item>
          <Form.Item name="description" label="Deskripsi" rules={[{ min: 3 }]}>
            <TextArea name="description" rows={5} onChange={handleChange} />
          </Form.Item>
          <Form.Item name="speaker" label="Speaker" rules={[{ min: 3 }]}>
            <Input name="speaker" onChange={handleChange} />
          </Form.Item>
          <div className="flex flex-col md:flex-row items-center gap-4">
            <Form.Item name="htm" label="Biaya">
              <InputNumber
                onChange={(e) => (newData.htm = e)}
                formatter={(value) =>
                  `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                }
                parser={(value) => value.replace(/Rp\s?|(\.*)/g, "")}
                style={{ width: "100%" }}
              />
            </Form.Item>
            <Form.Item name="costMemberGold" label="Biaya Member Gold">
              <InputNumber
                onChange={(e) => (newData.costMemberGold = e)}
                formatter={(value) =>
                  `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                }
                parser={(value) => value.replace(/Rp\s?|(\.*)/g, "")}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </div>
          <div className="flex flex-col md:flex-row items-center gap-4">
            <Form.Item name="fromDate" label="Waktu Mulai">
              <DatePicker
                format={dateFormat}
                placeholder="Pilih Tanggal"
                onChange={(e) => (newData.fromDate = e.format(dateFormat))}
              />
            </Form.Item>
            <Form.Item name="toDate" label="Waktu Selesai">
              <DatePicker
                onChange={(e) => (newData.toDate = e.format(dateFormat))}
                format={dateFormat}
                placeholder="Pilih Tanggal"
              />
            </Form.Item>
          </div>

          <div className="flex flex-col md:flex-row items-center gap-4">
            <Form.Item name="fromTime" label="Jam Mulai">
              <TimePicker
                format="HH:mm"
                onChange={(e) => (newData.fromTime = e.format(timeFormat))}
                placeholder="Pilih Jam"
              />
            </Form.Item>
            <Form.Item name="toTime" label="Jam Selesai">
              <TimePicker
                format="HH:mm"
                onChange={(e) => (newData.toTime = e.format(timeFormat))}
                placeholder="Pilih Jam"
              />
            </Form.Item>
          </div>
          <div className="flex flex-col md:flex-row items-center gap-4">
            <Form.Item name="location" label="Lokasi">
              <Input name="location" onChange={handleChange} />
            </Form.Item>
            <Form.Item name="showOnHome" label="Tampil di Homepage">
              <Radio.Group name="showOnHome" onChange={handleChange}>
                <Radio value={true}>tampil</Radio>
                <Radio value={false}>tidak</Radio>
              </Radio.Group>
            </Form.Item>
          </div>
          <Form.Item label="Gambar Banner">
            <Upload
              fileList={bannerList}
              accept=".jpg,.jpeg,.png"
              listType="picture-card"
              onPreview={handlePreview}
              onRemove={() => false}
              onChange={(file) => {
                if (file?.file.size > 3000000) return;
                else setBannerList(file.fileList);
              }}
              beforeUpload={(file) => {
                if (file.size <= 3000000) {
                  setBannerList([file]);
                  setNewBannerList([file]);
                  return false;
                }
                if (file.size > 3000000) {
                  message.error("Maks File 3MB");
                }
              }}
            >
              {bannerList?.length > 1
                ? null
                : bannerList.length === 0
                ? uploadButton
                : "Ganti"}
            </Upload>
          </Form.Item>
          <Form.Item label="Gambar Event">
            <Upload
              fileList={fileList}
              accept=".jpg,.jpeg,.png"
              listType="picture-card"
              onPreview={handlePreview}
              onChange={(file) => {
                if (file?.file.size > 3000000) return;
                else setFileList(file.fileList);
              }}
              onRemove={(file) => {
                setDeletedImages([...deletedImages, file?.name]);
                const index = fileList.indexOf(file);
                const newFileList = fileList.slice();
                newFileList.splice(index, 1);
                setFileList(newFileList);
              }}
              beforeUpload={(file) => {
                if (file.size <= 3000000) {
                  setFileList([...fileList, file]);
                  setNewFileList([...newFileList, file]);
                  return false;
                }
                if (file.size > 3000000) {
                  message.error("Maks File 2MB");
                }
              }}
            >
              {fileList?.length > 6 ? null : uploadButton}
            </Upload>
          </Form.Item>
        </Form>
      )}
      <Modal
        open={previewOpen}
        title={previewTitle}
        footer={null}
        width={800}
        style={{ top: 20 }}
        onCancel={() => setPreviewOpen(false)}
      >
        <img alt="example" style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </Modal>
  );
};

export default EditBusinessMatching;
