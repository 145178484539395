import { useQuery } from "react-query";
import { GetUrl } from "../../api/GetUrl";

export const useUsersPagination = ({ keyword, dataTable, role }) => {
  return useQuery(
    ["get-users-pagination", keyword, dataTable.page, dataTable.limit, role],
    () =>
      GetUrl(
        `/api/users?page=${dataTable.page}&limit=${dataTable.limit}&keyword=${keyword}&role=${role}`
      ),
    { refetchOnWindowFocus: false }
  );
};

export const useUsersDetail = (id) => {
  return useQuery(["get-user-detail", id], () => GetUrl(`/api/users/${id}`), {
    refetchOnWindowFocus: true,
    enabled: false,
  });
};

export const usePartners = () => {
  return useQuery("get-user-partners", () => GetUrl(`/api/partners`), {
    refetchOnWindowFocus: true,
  });
};
