import { EditOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { decryptCookies } from "components/helper/cookieshelper";
import {
  setFetchLearningsHeadline,
  setLearningsHeadlineId,
  setShowCreateLearningsHeadline,
  setShowEditLearningsHeadline,
} from "features/learnings/learnings-slice";
import { useLearningsHeadline } from "hook/learnings-hook/apiCalls";
import Cookies from "js-cookie";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CreateLearningsHeadline from "./Create";
import EditLearningsHeadline from "./Edit";

const HeadlineLearnings = () => {
  const dispatch = useDispatch();
  const { fetchLearningsHeadline } = useSelector((state) => state.learnings);

  const role =
    Cookies.get("dataUser") && JSON.parse(decryptCookies("dataUser")).role;

  const { data, refetch } = useLearningsHeadline();

  useEffect(() => {
    refetch();

    return () => dispatch(setFetchLearningsHeadline(false));
  }, [refetch, fetchLearningsHeadline, dispatch]);

  return (
    <>
      <div className="relative h-[calc(100vh-60px)] after:absolute after:block after:w-full after:min-h-screen after:md:min-h-[calc(100vh-60px)] after:md:h-[calc(100vh-60px)] after:left-0 after:top-0 after:z-20 after:bg-gradient-to-r after:from-[rgba(0,0,0,0.9)] after:to-[rgba(0,0,0,0.3)] overflow-hidden">
        {data?.data?.data?.length > 0 ? (
          <>
            <img
              src={data?.data?.data[0]?.banner}
              alt="webinars"
              className="object-cover object-center rounded-sm absolute top-0 left-0 w-full h-full z-10"
            />
            <div className="absolute top-[50%] translate-y-[-50%] left-4 right-4 md:left-10 z-30 max-w-[900px]">
              <h1 className="text-gray-100 text-4xl md:text-5xl mb-2">
                {data?.data?.data[0]?.title}
              </h1>
              <p className="text-gray-200">
                {data?.data?.data[0]?.description}
              </p>
            </div>
            {role === "admin_user" && (
              <div
                className="absolute top-[20px] right-10 z-30 max-w-[500px] text-xl text-white flex gap-3 drop-shadow-xl cursor-pointer font-[300]"
                onClick={() => {
                  if (role === "admin_user") {
                    dispatch(setLearningsHeadlineId(data?.data?.data[0]?.id));
                    dispatch(setShowEditLearningsHeadline(true));
                  } else return;
                }}
              >
                <EditOutlined />
                <span>Edit Headline</span>
              </div>
            )}
          </>
        ) : (
          role === "admin_user" && (
            <Button
              type="primary"
              icon={<PlusCircleOutlined />}
              disabled={role !== "admin_user"}
              onClick={() => {
                if (role === "admin_user")
                  dispatch(setShowCreateLearningsHeadline(true));
                else return;
              }}
              className="absolute top-[20px] right-10 z-30 flex items-center drop-shadow-xl font-[300]"
            >
              Add Headline
            </Button>
          )
        )}
      </div>

      <CreateLearningsHeadline />
      <EditLearningsHeadline />
    </>
  );
};

export default HeadlineLearnings;
