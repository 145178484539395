import { Button } from "antd";
import { decryptCookies } from "components/helper/cookieshelper";
import { usePaidLearningsDetail } from "hook/learnings-hook/apiCalls";
import Cookies from "js-cookie";
import moment from "moment";
import { useEffect, useState } from "react";
import { FaMicrophone } from "react-icons/fa";
import { FaCalendarDays } from "react-icons/fa6";
import { IoTimeSharp } from "react-icons/io5";
import { useNavigate, useParams } from "react-router-dom";
import MemberJoinPaidLearnings from "./MemberJoinPaidLearnings";

const format = "YYYY-MM-DD HH:mm:ss";

const DetailPaidLearnings = () => {
  const [showJoinMember, setShowJoinMember] = useState(false);

  const navigate = useNavigate();
  const { paid_id } = useParams();

  const { data, refetch } = usePaidLearningsDetail({ id: paid_id });

  useEffect(() => {
    refetch();
  }, [refetch]);

  const onCancel = () => setShowJoinMember(false);

  const isMember =
    Cookies.get("dataUser") && JSON.parse(decryptCookies("dataUser")).member;
  const token = Cookies.get("token");
  const role =
    Cookies.get("dataUser") && JSON.parse(decryptCookies("dataUser")).role;

  const isOpen =
    moment(data?.data?.from_date).format(format) <
    moment(new Date()).format(format);

  return (
    <>
      <div className="min-h-screen flex items-center p-4 xl:p-0 mt-[60px]">
        <div className="w-full flex flex-col xl:flex-row gap-4 xl:gap-8 xl:mt-20 xl:px-20">
          <div className="w-full xl:w-3/5  order-2 xl:order-1">
            <h1 className="font-bold text-3xl xl:text-6xl mb-4 max-w-[550px]">
              {data?.data?.title}
            </h1>
            <p className="text-gray-500 mt-6 mb-4">{data?.data?.description}</p>

            <p className="text-lg font-semibold">
              <div className="mb-2">
                <span className="block font-[400] text-gray-500">Cost</span>
                <span className="text-xl">
                  Rp
                  {data?.data?.cost
                    ?.toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                </span>
              </div>
              <div>
                <span className="block font-[400] text-gray-500">
                  Gold Member
                </span>
                <span className="text-xl">
                  Rp
                  {data?.data?.cost_member_gold
                    ?.toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                </span>
              </div>
            </p>
            <Button
              type="primary"
              size="large"
              className={`my-2 flex items-center rounded-md ${
                isOpen || role === "corporate_user"
                  ? ""
                  : "!bg-blue-600 hover:!bg-blue-500"
              }`}
              onClick={() => {
                if (isOpen) {
                  return;
                } else {
                  if (role === "admin_user") {
                    alert("Admin tidak bisa join webinar!");
                    return;
                  } else if (isMember || token) setShowJoinMember(true);
                  else {
                    navigate("/user-auth");
                    Cookies.set("isNavigate", true);
                  }
                }
              }}
              disabled={isOpen || role === "corporate_user"}
            >
              {isOpen ? "Webinar Closed" : "Join Webinar"}
            </Button>

            <div className="mt-4 xl:mt-10 p-6 border-t border-t-gray-300 grid grid-cols-1 xl:grid-cols-2 items-center gap-5">
              <div className="flex flex-col xl:flex-row gap-3 xl:items-center mb-5 xl:mb-0">
                <img
                  src={data?.data?.photo_speaker}
                  alt="speaker"
                  className="w-[100px] h-[100px] rounded-full"
                />
                <div>
                  <div className="flex items-center gap-1 mb-2 text-lg">
                    <FaMicrophone />
                    <h3 className="font-semibold">{data?.data?.speaker}</h3>
                  </div>
                  <p className="text-gray-600 text-sm">
                    {data?.data?.position}
                  </p>
                </div>
              </div>
              <div>
                <div className="flex gap-1 items-center mb-2">
                  <FaCalendarDays className="text-gray-600" />
                  <p>
                    {moment(data?.data?.from_date).format("LL")} -
                    {moment(data?.data?.from_date).format("LL") ===
                    moment(data?.data?.to_date).format("LL")
                      ? ""
                      : moment(data?.data?.to_date).format("LL")}
                  </p>
                </div>
                <div className="flex gap-1 items-center">
                  <IoTimeSharp />
                  <p>
                    {moment(data?.data?.from_date).format("HH:mm")} -{" "}
                    {moment(data?.data?.to_date).format("HH:mm")}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full xl:w-2/5 flex xl:h-screen order-1 xl:order-2">
            <img
              src={data?.data?.banner}
              alt="webinars"
              className="w-full max-w-[400px] h-full xl:max-h-[350px] rounded-md object-cover object-center"
            />
          </div>
        </div>
      </div>

      <MemberJoinPaidLearnings
        idLearnings={paid_id}
        show={showJoinMember}
        onCancel={onCancel}
      />
    </>
  );
};

export default DetailPaidLearnings;
