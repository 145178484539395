import { Badge, Table } from "antd";
import TableHeader from "components/TableHeader";
import { decryptCookies } from "components/helper/cookieshelper";
import { useBusinessMatchingDetail } from "hook/business-matching-hook/apiCalls";
import Cookies from "js-cookie";
import moment from "moment";
import { useState } from "react";
import { useParams } from "react-router-dom";

const CorporateMasterBookingMatching = () => {
  const [dataTable, setDataTable] = useState({ limit: 20, page: 1 });
  const { matching_id } = useParams();
  const corporateId =
    Cookies.get("dataUser") && JSON.parse(decryptCookies("dataUser")).id;

  const { data, isLoading } = useBusinessMatchingDetail({
    id: matching_id,
    corporateId,
  });

  // const onUpdate = async (status, id) => {
  //   try {
  //     const { data } = await axios.put(
  //       REACT_APP_HOST_API + "/api/event-offline/corporate/users/" + id,
  //       { status },
  //       { headers: { Authorization: "Bearer " + decryptCookies("token") } }
  //     );

  //     message.success(data.message);
  //     refetch();
  //   } catch (error) {
  //     alert(error.message);
  //   }
  // };

  const dataSource =
    data?.data?.corporateEventOffline[0]?.userBookEventOffline?.map((x, i) => ({
      ...x,
      key: x.id,
      index: i + 1,
      username: x?.user?.username,
      email: x?.user?.email,
      phone: x?.user?.phone,
    }));

  const columns = [
    { title: "No", dataIndex: "index", width: 50 },
    { title: "Nama", dataIndex: "username" },
    { title: "Email", dataIndex: "email" },
    { title: "No Telp", dataIndex: "phone" },
    {
      title: "Tanggal",
      dataIndex: "date",
      render: (x) => moment(x).format("LL"),
    },
    {
      title: "Waktu",
      render: (_, record) => record.from_time + " - " + record.to_time,
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (x) => (
        <Badge
          status={
            x === "waiting"
              ? "processing"
              : x === "approve"
              ? "success"
              : "error"
          }
          text={
            x === "waiting"
              ? "menunggu"
              : x === "approve"
              ? "diterima"
              : "ditolak"
          }
        />
      ),
    },
    // {
    //   title: "Bukti Bayar",
    //   dataIndex: "image",
    //   render: (x) => (
    //     <Fragment>
    //       {x ? (
    //         <Tag
    //           color="blue"
    //           onClick={() => {
    //             setImgUrl(x);
    //             setVisible(true);
    //           }}
    //           className="cursor-pointer"
    //         >
    //           Lihat
    //         </Tag>
    //       ) : (
    //         "-"
    //       )}
    //     </Fragment>
    //   ),
    // },
    // {
    //   title: "Aksi",
    //   align: "center",
    //   render: (_, record) => (
    //     <Fragment>
    //       {record.status === "waiting" &&
    //         moment(new Date()).format("YYYY-MM-DD") < record.date && (
    //           <Space>
    //             {/* <Popconfirm
    //               title="Anda yakin ingin menolak booking ini?"
    //               okText="Reject"
    //               onConfirm={() => onUpdate("rejected", record.id)}
    //             >
    //               <Tag
    //                 color="magenta"
    //                 style={{ cursor: "pointer" }}
    //                 // hidden={record.status === "rejected"}
    //               >
    //                 Reject
    //               </Tag>
    //             </Popconfirm> */}
    //             <Popconfirm
    //               title="Anda yakin ingin menerima booking ini?"
    //               okText="Approve"
    //               onConfirm={() => onUpdate("approve", record.id)}
    //             >
    //               <Tag
    //                 color="green"
    //                 style={{ cursor: "pointer" }}
    //                 // hidden={record.status === "approve"}
    //               >
    //                 Terima
    //               </Tag>
    //             </Popconfirm>
    //           </Space>
    //         )}
    //     </Fragment>
    //   ),
    // },
  ];

  const pagination = {
    current: dataTable.page,
    pageSize: dataTable.limit,
    defaultPageSize: dataTable.limit,
    total: data?.data?.meta?.total,
    showSizeChanger: true,
    pageSizeOptions: [10, 20, 50, 100],
    onChange: (curr, size) => {
      setDataTable((value) => {
        return {
          ...value,
          page: curr,
          limit: size,
        };
      });
    },
  };
  return (
    <div>
      <TableHeader title={`${data?.data?.title} - Bookings`} addBtn={false} />
      <Table
        dataSource={dataSource}
        columns={columns}
        size="small"
        loading={isLoading}
        pagination={pagination}
        scroll={{ x: 800 }}
      />
      {/* <Image
        width={200}
        style={{
          display: "none",
        }}
        src={imgUrl}
        preview={{
          visible,
          src: imgUrl,
          onVisibleChange: (value) => {
            setVisible(value);
          },
        }}
      /> */}
    </div>
  );
};

export default CorporateMasterBookingMatching;
