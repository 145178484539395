import { useQuery } from "react-query";
import { GetUrl } from "../../api/GetUrl";

export const useHeadlinesPagination = ({ keyword, dataTable }) => {
  return useQuery(
    ["get-headlines-pagination", keyword, dataTable.page, dataTable.limit],
    () =>
      GetUrl(
        `/api/headlines?page=${dataTable.page}&limit=${dataTable.limit}&keyword=${keyword}`
      ),
    { refetchOnWindowFocus: false }
  );
};

export const useHeadlinesDetail = (id) => {
  return useQuery(
    ["get-headlines-detail", id],
    () => GetUrl(`/api/headlines/${id}`),
    { refetchOnWindowFocus: false, enabled: false }
  );
};
