import {
  DeleteOutlined,
  EditOutlined,
  LinkedinOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { Button, Popconfirm, Space, Tag } from "antd";
import { DeleteApi } from "api/DeleteApi";
import Container from "components/Container";
import Section from "components/Section";
import { decryptCookies } from "components/helper/cookieshelper";
import {
  setFetchTeam,
  setShowCreateTeam,
  setShowEditTeam,
  setTeamId,
} from "features/about-us/aboutus-slice";
import { useAboutTeamPagination } from "hook/about-us-hook/apiCalls";
import Cookies from "js-cookie";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CreateTeam from "./Create";
import EditTeam from "./Edit";

const Team = () => {
  const dispatch = useDispatch();
  const userToken = useSelector((state) => state.auth.token);
  const { fetchTeam } = useSelector((state) => state.about);

  const role =
    Cookies.get("dataUser") && JSON.parse(decryptCookies("dataUser")).role;

  const { data, refetch } = useAboutTeamPagination({
    dataTable: { page: 1, limit: 100 },
  });

  useEffect(() => {
    refetch();

    return () => dispatch(setFetchTeam(false));
  }, [refetch, dispatch, fetchTeam]);

  return (
    <>
      <Section className="py-20">
        <Container className="md:px-10">
          <div className="text-center">
            <Space>
              <h2 className="text-2xl lg:text-4xl text-gray-900 font-[500]">
                Meet our team
              </h2>
              {userToken && role === "admin_user" && (
                <Button
                  hidden={role !== "admin_user"}
                  disabled={role !== "admin_user"}
                  icon={<PlusCircleOutlined />}
                  onClick={() => {
                    if (role === "admin_user")
                      dispatch(setShowCreateTeam(true));
                    else return;
                  }}
                >
                  Add Team
                </Button>
              )}
            </Space>

            {/* <div className="mt-10 flex flex-col gap-10 lg:gap-28">
              <div>
                <DividerComponent className="text-left" orientationMargin={0}>
                  Commissioners
                </DividerComponent>
                <div className="flex flex-wrap items-center gap-4 md:gap-20 mt-10">
                  {data?.data?.data
                    ?.filter((x) => x.title === "commissioner")
                    .map((team) => (
                      <div className="mb-6 md:mb-0 flex items-center gap-6">
                        <img
                          src={team.image}
                          alt={team.name}
                          className="w-[100px] h-[100px] lg:w-[150px] lg:h-[150px] object-cover object-center rounded-full mb-2"
                        />
                        <div className="text-left">
                          <h3 className="text-xl font-[500] mb-2">
                            {team.name}
                          </h3>
                          <a href={team.link} target="_blank" rel="noreferrer">
                            <Tag icon={<LinkedinOutlined />} color="#55acee">
                              LinkedIn
                            </Tag>
                          </a>

                          {role === "admin_user" && userToken && (
                            <div className="flex mt-2 gap-2">
                              <Popconfirm
                                title="Anda yakin akan menghapus data?"
                                okText="Delete"
                                onConfirm={() =>
                                  DeleteApi({
                                    dataId: team.id,
                                    url: "/api/aboutus/ourteam/",
                                    refetch,
                                  })
                                }
                              >
                                <Button size="small" icon={<DeleteOutlined />}>
                                  Delete
                                </Button>
                              </Popconfirm>
                              <Button
                                size="small"
                                icon={<EditOutlined />}
                                onClick={() => {
                                  dispatch(setTeamId(team.id));
                                  dispatch(setShowEditTeam(true));
                                }}
                              >
                                Edit
                              </Button>
                            </div>
                          )}
                        </div>
                      </div>
                    ))}
                </div>
              </div>
              <div>
                <DividerComponent className="text-left" orientationMargin={0}>
                  Directors
                </DividerComponent>
                <div className="flex flex-wrap items-center gap-4 md:gap-20 mt-10">
                  {data?.data?.data
                    ?.filter((x) => x.title === "director")
                    .map((team) => (
                      <div className="mb-6 md:mb-0 flex items-center gap-6">
                        <img
                          src={team.image}
                          alt={team.name}
                          className="w-[100px] h-[100px] lg:w-[150px] lg:h-[150px] object-cover object-center rounded-full mb-2"
                        />
                        <div className="text-left">
                          <h3 className="text-xl font-[500] mb-2">
                            {team.name}
                          </h3>
                          <a href={team.link} target="_blank" rel="noreferrer">
                            <Tag icon={<LinkedinOutlined />} color="#55acee">
                              LinkedIn
                            </Tag>
                          </a>

                          {role === "admin_user" && userToken && (
                            <div className="flex mt-2 gap-2">
                              <Popconfirm
                                title="Anda yakin akan menghapus data?"
                                okText="Delete"
                                onConfirm={() =>
                                  DeleteApi({
                                    dataId: team.id,
                                    url: "/api/aboutus/ourteam/",
                                    refetch,
                                  })
                                }
                              >
                                <Button size="small" icon={<DeleteOutlined />}>
                                  Delete
                                </Button>
                              </Popconfirm>
                              <Button
                                size="small"
                                icon={<EditOutlined />}
                                onClick={() => {
                                  dispatch(setTeamId(team.id));
                                  dispatch(setShowEditTeam(true));
                                }}
                              >
                                Edit
                              </Button>
                            </div>
                          )}
                        </div>
                      </div>
                    ))}
                </div>
              </div>
              <div>
                <DividerComponent className="text-left" orientationMargin={0}>
                  Staffs
                </DividerComponent>
                <div className="flex flex-wrap items-center gap-4 md:gap-20 mt-10">
                  {data?.data?.data
                    ?.filter((x) => x.title === "staff")
                    .map((team) => (
                      <div className="mb-6 md:mb-0 flex items-center gap-6">
                        <img
                          src={team.image}
                          alt={team.name}
                          className="w-[100px] h-[100px] lg:w-[150px] lg:h-[150px] object-cover object-center rounded-full mb-2"
                        />
                        <div className="text-left">
                          <h3 className="text-xl font-[500] mb-2">
                            {team.name}
                          </h3>
                          <a href={team.link} target="_blank" rel="noreferrer">
                            <Tag icon={<LinkedinOutlined />} color="#55acee">
                              LinkedIn
                            </Tag>
                          </a>

                          {role === "admin_user" && userToken && (
                            <div className="flex mt-2 gap-2">
                              <Popconfirm
                                title="Anda yakin akan menghapus data?"
                                okText="Delete"
                                onConfirm={() =>
                                  DeleteApi({
                                    dataId: team.id,
                                    url: "/api/aboutus/ourteam/",
                                    refetch,
                                  })
                                }
                              >
                                <Button size="small" icon={<DeleteOutlined />}>
                                  Delete
                                </Button>
                              </Popconfirm>
                              <Button
                                size="small"
                                icon={<EditOutlined />}
                                onClick={() => {
                                  dispatch(setTeamId(team.id));
                                  dispatch(setShowEditTeam(true));
                                }}
                              >
                                Edit
                              </Button>
                            </div>
                          )}
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div> */}

            <div className="flex flex-wrap justify-center items-center gap-4 md:gap-20 mt-10 md:mt-20 mb-10">
              {data?.data?.data?.map((x) => (
                <div className="mb-6 md:mb-0 flex items-center gap-6">
                  <img
                    src={x.image}
                    alt={x.name}
                    className="w-[150px] h-[150px] object-cover object-center rounded-full mb-2"
                  />
                  <div className="text-left">
                    <h3 className="text-xl font-[500]">{x.name}</h3>
                    <p className="text-[14px]  mb-3">{x.title}</p>
                    <a href={x.link} target="_blank" rel="noreferrer">
                      <Tag icon={<LinkedinOutlined />} color="#55acee">
                        LinkedIn
                      </Tag>
                    </a>

                    {role === "admin_user" && userToken && (
                      <div className="flex mt-2 gap-2">
                        <Popconfirm
                          title="Anda yakin akan menghapus data?"
                          okText="Delete"
                          onConfirm={() =>
                            DeleteApi({
                              dataId: x.id,
                              url: "/api/aboutus/ourteam/",
                              refetch,
                            })
                          }
                        >
                          <Button size="small" icon={<DeleteOutlined />}>
                            Delete
                          </Button>
                        </Popconfirm>
                        <Button
                          size="small"
                          icon={<EditOutlined />}
                          onClick={() => {
                            dispatch(setTeamId(x.id));
                            dispatch(setShowEditTeam(true));
                          }}
                        >
                          Edit
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Container>
      </Section>

      <CreateTeam />
      <EditTeam />
    </>
  );
};

export default Team;
