import { EditOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Button, Space } from "antd";
import { decryptCookies } from "components/helper/cookieshelper";
import {
  setFetchMission,
  setMissionId,
  setShowCreateMission,
  setShowEditMission,
} from "features/about-us/aboutus-slice";
import { useAboutMission } from "hook/about-us-hook/apiCalls";
import Cookies from "js-cookie";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CreateMission from "./Create";
import EditMission from "./Edit";

const Mission = () => {
  const dispatch = useDispatch();
  const userToken = useSelector((state) => state.auth.token);
  const { fetchMission } = useSelector((state) => state.about);

  const { data, refetch } = useAboutMission();

  useEffect(() => {
    refetch();

    return () => dispatch(setFetchMission(false));
  }, [refetch, dispatch, fetchMission]);

  const role =
    Cookies.get("dataUser") && JSON.parse(decryptCookies("dataUser")).role;

  return (
    <>
      <div className="flex-1">
        {data?.data?.data?.length === 0 &&
        role === "admin_user" &&
        userToken ? (
          <Button
            icon={<PlusCircleOutlined />}
            hidden={role !== "admin_user"}
            disabled={role !== "admin_user"}
            onClick={() => {
              if (role === "admin_user") dispatch(setShowCreateMission(true));
              else return;
            }}
          >
            Add Mission
          </Button>
        ) : (
          <>
            <Space className="w-full mb-3 pb-3 border-b items-center">
              <h1 className="text-xl font-[500]">
                {data?.data?.data[0]?.title}
              </h1>
              {role === "admin_user" && (
                <Button
                  size="small"
                  icon={<EditOutlined />}
                  disabled={role !== "admin_user"}
                  hidden={role !== "admin_user"}
                  onClick={() => {
                    if (role === "admin_user") {
                      dispatch(setMissionId(data.data.data[0].id));
                      dispatch(setShowEditMission(true));
                    } else return;
                  }}
                >
                  Edit Mission
                </Button>
              )}
            </Space>

            <p>{data?.data?.data[0]?.text}</p>
          </>
        )}
      </div>
      <CreateMission />
      <EditMission />
    </>
  );
};

export default Mission;
