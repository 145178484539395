import { Image, Tabs } from "antd";
import Container from "components/Container";
import Section from "components/Section";
import React from "react";

const Info = ({ data }) => {
  const onChange = (key) => {};

  const items = [
    {
      key: "1",
      label: <p className="text-lg">Photos</p>,
      children: (
        <div className="flex flex-wrap gap-5">
          {data?.data?.images?.map((image, i) => (
            <Image
              key={i}
              src={image}
              alt="gallery"
              className="w-full max-w-[400px] object-cover object-center rounded-md"
            />
          ))}
        </div>
      ),
    },
  ];

  return (
    <Section>
      <Container>
        <div>
          <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
        </div>
      </Container>
    </Section>
  );
};

export default Info;
