import { Form, Modal, Select, message } from "antd";
import axios from "axios";
import { decryptCookies } from "components/helper/cookieshelper";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const { REACT_APP_HOST_API: api } = process.env;

const AddMatchingContributors = ({ showModal, onCancel, onSuccess }) => {
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const { matching_id } = useParams();

  const fetchCorporates = useCallback(async () => {
    try {
      const { data } = await axios.get(
        api +
          `/api/user-corporates-not-in-event-offline?eventOfflineId=${matching_id}&keyword=`
      );
      setData(data?.data);
    } catch (error) {
      alert(error.message);
    }
  }, [matching_id]);

  useEffect(() => {
    if (showModal) fetchCorporates();
  }, [fetchCorporates, showModal]);

  const onSubmit = () => {
    form
      .validateFields()
      .then(async (values) => {
        setLoading(true);
        try {
          const { data } = await axios.post(
            api + "/api/event-offline/corporates",
            {
              userId: values.userId,
              eventOfflineId: matching_id,
            },
            { headers: { Authorization: "Bearer " + decryptCookies("token") } }
          );
          message.success(data.message);
          onCancelModal();
          onSuccess();
        } catch (error) {
          alert(error?.response?.data?.message);
        } finally {
          setLoading(false);
        }
      })
      .catch(() => {});
  };

  const onCancelModal = () => {
    onCancel();
    form.resetFields();
  };

  return (
    <Modal
      open={showModal}
      okText="Submit"
      cancelText="Batal"
      onOk={onSubmit}
      onCancel={onCancelModal}
      okButtonProps={{ loading }}
      title="Add Company Contributor"
    >
      <Form form={form} layout="vertical">
        <Form.Item name="userId" label="Company" rules={[{ required: true }]}>
          <Select
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
          >
            {data?.map((user) => (
              <Select.Option key={user.id} value={user.id}>
                {user.username}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddMatchingContributors;
