import { configureStore } from "@reduxjs/toolkit";
import aboutusSlice from "features/about-us/aboutus-slice";
import authSlice from "features/auth/auth-slice";
import businessMatchingSlice from "features/business-matching/business-matching-slice";
import layoutSlice from "features/layout/layout-slice";
import userMemberSlice from "features/member/userMember-slice";
import newsInfoSlice from "features/news/newsInfo-slice";
import productsSlice from "features/products/products-slice";
import projectsSlice from "features/projects/projects-slice";
import servicesSlice from "features/services/services-slice";
import tradeMissionSlice from "features/trade-mission/trade-mission-slice";
import learningsSlice from "features/learnings/learnings-slice";

export const store = configureStore({
  reducer: {
    auth: authSlice,
    layout: layoutSlice,
    userMember: userMemberSlice,
    tradeMission: tradeMissionSlice,
    businessMatching: businessMatchingSlice,
    products: productsSlice,
    about: aboutusSlice,
    services: servicesSlice,
    news: newsInfoSlice,
    projects: projectsSlice,
    learnings: learningsSlice,
  },
});
