import { useQuery } from "react-query";
import { GetUrl } from "../../api/GetUrl";

export const useNewsPagination = ({ keyword = "", dataTable }) => {
  return useQuery(
    ["get-news-pagination", keyword, dataTable.page, dataTable.limit],
    () =>
      GetUrl(
        `/api/news-info-contents?page=${dataTable.page}&limit=${dataTable.limit}&keyword=${keyword}`
      ),
    { refetchOnWindowFocus: true }
  );
};

export const useNewsDetail = ({ id }) => {
  return useQuery(
    ["get-news-detail", id],
    () => GetUrl(`/api/news-info-contents/${id}`),
    { refetchOnWindowFocus: false }
  );
};

export const useNewsHeadline = () => {
  return useQuery(
    "get-news-headlines-pagination",
    () => GetUrl(`/api/news-info-headlines?page=1&limit=10&keyword=`),
    { refetchOnWindowFocus: false }
  );
};
export const useNewsHeadlinesDetail = (id) => {
  return useQuery(
    ["get-news-headlines-detail", id],
    () => GetUrl(`/api/news-info-headlines/${id}`),
    { refetchOnWindowFocus: false }
  );
};
