import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  servicesId: "",
  showCreateServices: false,
  showEditServices: false,
  fetchServices: false,
};

const servicesSlice = createSlice({
  name: "services",
  initialState,
  reducers: {
    setServicesId: (state, action) => {
      state.servicesId = action.payload;
    },
    setShowCreateServices: (state, action) => {
      state.showCreateServices = action.payload;
    },
    setShowEditServices: (state, action) => {
      state.showEditServices = action.payload;
    },
    setFetchServices: (state, action) => {
      state.fetchServices = action.payload;
    },
  },
});

export const {
  setFetchServices,
  setServicesId,
  setShowCreateServices,
  setShowEditServices,
} = servicesSlice.actions;
export default servicesSlice.reducer;
