import { Button, Divider, Drawer, Dropdown, Menu, Space, theme } from "antd";
import User from "assets/user.svg";
import Container from "components/Container";
import Loading from "components/Loading";
import { decryptCookies } from "components/helper/cookieshelper";
import { logout } from "components/helper/logout";
import { setAuthContent, setToken } from "features/auth/auth-slice";
import {
  setDefaultAdminMenu,
  setDefaultCorporateMenu,
  setDefaultUserMenu,
} from "features/layout/layout-slice";
import { useContactUsPagination } from "hook/contactUs-hook/apiCalls";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { BsStars } from "react-icons/bs";
import { CgMenu } from "react-icons/cg";
import { FaAngleDown } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import "./navigation.css";
import { useUsersDetail } from "hook/users-hook/apiCalls";

const { useToken } = theme;

const Navigation = () => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [navScroll, setNavScroll] = useState(false);
  const { token } = useToken();

  const navigate = useNavigate();

  const userToken = useSelector((state) => state.auth.token);

  const role =
    Cookies.get("dataUser") && JSON.parse(decryptCookies("dataUser")).role;
  const username =
    Cookies.get("dataUser") && JSON.parse(decryptCookies("dataUser")).username;
  const email =
    Cookies.get("dataUser") && JSON.parse(decryptCookies("dataUser")).email;
  const userId =
    Cookies.get("dataUser") && JSON.parse(decryptCookies("dataUser")).id;
  const member =
    Cookies.get("dataUser") &&
    JSON.parse(decryptCookies("dataUser")).member?.name;

  const dispatch = useDispatch();

  const handleScroll = () => {
    if (window.scrollY > 100) setNavScroll(true);
    else setNavScroll(false);
  };

  window.addEventListener("scroll", handleScroll);

  // const fakeLoading = (time) =>
  //   new Promise((resolve) => setTimeout(resolve, time));

  const handleLogout = async () => {
    setLoading(true);
    // await fakeLoading(2000);
    dispatch(setToken(""));
    setLoading(false);
    logout();
    window.location.reload();
  };

  const handleClickProfile = () => {
    if (role === "personal_user") {
      Cookies.set("defaultUserMenu", "/user");
      dispatch(setDefaultUserMenu("/user"));
      navigate("/user");
    } else if (role === "corporate_user") {
      Cookies.set("defaultCorporateMenu", "/corporate");
      dispatch(setDefaultCorporateMenu("/corporate"));
      navigate("/corporate");
    } else {
      Cookies.set("defaultAdminMenu", "/admin");
      dispatch(setDefaultAdminMenu("/admin"));
      navigate("/admin");
    }
  };

  const items = [
    { key: "/", label: "Home" },
    { key: "/about-us", label: "About Us" },
    {
      key: "events",
      label: "Events",
      children: [
        { key: "/trade-mission", label: "Trade Missions" },
        { key: "/business-matching", label: "Business Matching" },
        { key: "/learnings", label: "Biznids Learning" },
      ],
    },
    { key: "/partners", label: "Partners" },
    { key: "/news", label: "News & Info" },
    { key: "/projects", label: "Projects" },
    { key: "/banks", label: "Bank Accounts" },
  ];

  const handleClickMenu = (param) => {
    if (param.key === "profile") {
      role === "personal_user"
        ? navigate("/user")
        : role === "admin_user"
        ? navigate("/admin")
        : navigate("/corporate");
      return;
    }

    navigate(param.key.toLowerCase());
    setOpen(false);
  };

  const onClose = () => setOpen(false);

  const contentStyle = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
  };

  const { data } = useContactUsPagination({
    dataTable: { page: 1, limit: 10 },
  });

  const { data: dataUser, refetch } = useUsersDetail(userId);

  useEffect(() => {
    if (!userId) return;
    refetch();
  }, [userId, refetch]);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <nav
          className={`nav duration-200 ${
            navScroll ? "shadow-md bg-white" : "bg-[#0242E8]"
          }`}
        >
          <Container className="w-[95%] flex items-center justify-between h-full pr-12 md:pr-8 lg:pr-0">
            <div className="logo" onClick={() => navigate("/")}>
              <img src={data?.data?.data[0].logo} alt="" />
            </div>
            <ul className={`nav-items ${navScroll ? "scroll" : ""}`}>
              <li className="item">
                <Link to="/">Home</Link>
              </li>
              <li className="item">
                <Link to="/about-us">About Us</Link>
              </li>
              <Dropdown
                placement="bottomRight"
                dropdownRender={() => (
                  <div style={contentStyle} className="border border-[#ccc]">
                    <div className="flex flex-col">
                      <Link
                        to="/trade-mission"
                        className="py-2 px-5 hover:bg-gray-100 hover:text-gray-700"
                      >
                        Trade Mission
                      </Link>
                      <Link
                        to="/business-matching"
                        className="py-2 px-5 hover:bg-gray-100 hover:text-gray-700"
                      >
                        Business Matching
                      </Link>
                      <Link
                        to="/learnings"
                        className="py-2 px-5 hover:bg-gray-100 hover:text-gray-700"
                      >
                        Biznids Learning
                      </Link>
                    </div>
                  </div>
                )}
              >
                <div className="item">
                  <Space style={{ justifyItems: "center" }}>
                    Events
                    <FaAngleDown />
                  </Space>
                </div>
              </Dropdown>
              <li className="item">
                <Link to="/partners">Partners</Link>
              </li>
              <li className="item">
                <Link to="/news">News & Info</Link>
              </li>
              <li className="item">
                <Link to="/projects">Projects</Link>
              </li>
              <li className="item">
                <Link to="/banks">Bank Accounts</Link>
              </li>
            </ul>
            {userToken ? (
              <Dropdown
                trigger={["click"]}
                placement="bottomRight"
                className="ml-3"
                dropdownRender={() => (
                  <div style={contentStyle} className="border border-[#ccc]">
                    <div className="py-4 px-6">
                      <h2 className="text-[15px]">
                        {username ? username : "Guest"}
                      </h2>
                      <p className="text-xs">{email ? email : "no email"}</p>
                    </div>
                    <Divider style={{ margin: 0 }} />
                    <Divider style={{ margin: 0 }} />
                    <div className="py-4 px-6">
                      <h1 className="font-semibold mb-1">Membership</h1>
                      <Space>
                        <p>{member ? member : ""}</p>
                        <BsStars
                          color={`${member === "Gold" ? "orange" : "gray"}`}
                          className="text-[16px]"
                        />
                      </Space>
                    </div>
                    {member === "Silver" && (
                      <div className="p-2">
                        <Button
                          type="text"
                          block
                          hidden={member === "Gold"}
                          disabled={member === "Gold"}
                          className="py-5 px-6 flex items-center border border-yellow-600 bg-yellow-50 hover:!bg-yellow-100 duration-200"
                          onClick={handleClickProfile}
                        >
                          Upgrade to Gold
                        </Button>
                      </div>
                    )}
                    <Divider style={{ margin: 0 }} />
                    <div className="p-2">
                      <Button
                        type="text"
                        onClick={handleClickProfile}
                        block
                        className="py-5 px-6 flex items-center border border-gray-400 hover:!bg-gray-200 duration-200"
                      >
                        Dashboard
                      </Button>
                    </div>
                    <div className="p-2">
                      <Button
                        type="text"
                        onClick={handleLogout}
                        block
                        className="py-5 px-6 flex items-center bg-black text-white hover:!bg-gray-700 hover:!text-gray-50 duration-200"
                      >
                        Logout
                      </Button>
                    </div>
                  </div>
                )}
              >
                <div className="w-[30px] h-[30px] mr-1 rounded-full cursor-pointer overflow-hidden text-gray-200 box-border">
                  <img
                    src={
                      role === "admin_user"
                        ? data?.data?.data[0]?.logo
                        : dataUser?.data?.image
                        ? dataUser?.data?.image
                        : User
                    }
                    alt="profile"
                    className="bg-white w-[30px] h-[30px] object-cover object-center"
                  />
                </div>
              </Dropdown>
            ) : (
              <Space className="ml-4">
                <Button
                  onClick={() => {
                    dispatch(setAuthContent("sign-in"));
                    navigate("/user-auth");
                    Cookies.set("isNavigate", true);
                  }}
                  className={`${
                    navScroll
                      ? "border-blue-500"
                      : "text-white hover:!text-white"
                  } auth-btn`}
                >
                  Login
                </Button>
                <Button
                  type="primary"
                  onClick={() => {
                    dispatch(setAuthContent("sign-up"));
                    navigate("/user-auth");
                    Cookies.set("isNavigate", true);
                  }}
                  className={`${
                    navScroll
                      ? ""
                      : "!bg-white text-gray-600 hover:!text-gray-600"
                  } auth-btn`}
                >
                  Sign up
                </Button>
              </Space>
            )}
            <div className="burger-menu" onClick={() => setOpen(true)}>
              <CgMenu className={`${navScroll ? "" : "text-white"}`} />
            </div>

            <Drawer
              onClose={onClose}
              open={open}
              width={270}
              styles={{ body: { padding: 0 } }}
            >
              <Menu
                style={{ fontSize: "1.05em" }}
                mode="inline"
                items={items}
                theme="light"
                onClick={handleClickMenu}
              />
            </Drawer>
          </Container>
        </nav>
      )}
    </>
  );
};

export default Navigation;
