import CryptoJS from "crypto-js";

const { REACT_APP_SECRET_KEY } = process.env;

export const encryptValue = (value) => {
  const encryptedValue = CryptoJS.AES.encrypt(
    value,
    REACT_APP_SECRET_KEY
  ).toString();

  return encryptedValue;
};

export const decryptValue = (value) => {
  const decryptedValue = CryptoJS.AES.decrypt(
    value,
    REACT_APP_SECRET_KEY
  ).toString(CryptoJS.enc.Utf8);

  return decryptedValue;
};
