import { Form, Input, Modal, message } from "antd";
import axios from "axios";
import { decryptCookies } from "components/helper/cookieshelper";
import { useState } from "react";

const { REACT_APP_HOST_API: url } = process.env;

const CreateBank = ({ show, onCancel, onCreate }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    const values = await form.validateFields();

    setLoading(true);
    try {
      const { data } = await axios.post(url + "/api/rekenings", values, {
        headers: { Authorization: "Bearer " + decryptCookies("token") },
      });
      message.success(data.message);
      onCreate();
      onCancelModal();
    } catch (error) {
      alert(error.message);
    } finally {
      setLoading(false);
    }
  };

  const onCancelModal = () => {
    form.resetFields();
    onCancel();
  };

  return (
    <Modal
      open={show}
      okText="Submit"
      onCancel={onCancelModal}
      onOk={handleSubmit}
      okButtonProps={{ loading }}
    >
      <Form form={form} layout="vertical">
        <Form.Item label="Nama Bank" name="name" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item
          label="No Rekening"
          name="noRek"
          rules={[
            { required: true },
            {
              pattern: new RegExp("^([0-9]*)$"),
              message: "Masukkan hanya Nomor",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Atas Nama" name="an" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateBank;
