import { DatePicker, Input, Space, Table, Tag } from "antd";
import TableHeader from "components/TableHeader";
import { decryptCookies } from "components/helper/cookieshelper";
import { useBusinessMatchingPagination } from "hook/business-matching-hook/apiCalls";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const UserMasterBusinessMatching = () => {
  const [keyword, setKeyword] = useState("");
  const [date, setDate] = useState({ from: "", to: "" });
  const [dataTable, setDataTable] = useState({ limit: 20, page: 1 });

  const navigate = useNavigate();
  const userId =
    Cookies.get("dataUser") && JSON.parse(decryptCookies("dataUser")).id;

  const { data, isLoading, refetch } = useBusinessMatchingPagination({
    date,
    keyword,
    dataTable,
    userId,
  });

  const dataSource = data?.data?.data?.map((x, i) => ({
    ...x,
    key: x.id,
    index: i + 1,
  }));

  useEffect(() => {
    refetch();
  }, [refetch]);

  const columns = [
    { title: "No", dataIndex: "index", width: 50 },
    { title: "Nama", dataIndex: "title" },
    { title: "Speaker", dataIndex: "speaker" },
    { title: "Tanggal Mulai", dataIndex: "from_date" },
    { title: "Tanggal Selesai", dataIndex: "to_date" },
    {
      title: "Aksi",
      dataIndex: "id",
      align: "center",
      render: (id) => (
        <Space>
          <Tag
            color="blue"
            style={{ cursor: "pointer" }}
            onClick={() => navigate(`${id}/bookings`)}
          >
            Bookings
          </Tag>
        </Space>
      ),
    },
  ];

  const pagination = {
    current: dataTable.page,
    pageSize: dataTable.limit,
    defaultPageSize: dataTable.limit,
    total: data?.data.meta.total,
    showSizeChanger: true,
    pageSizeOptions: [10, 20, 50, 100],
    onChange: (curr, size) => {
      setDataTable((value) => {
        return {
          ...value,
          page: curr,
          limit: size,
        };
      });
    },
  };
  return (
    <div>
      <TableHeader title="Business Matching" addBtn={false} />
      <div className="table-filter">
        <div className="filter">
          <Input
            placeholder="cari nama"
            onChange={({ target: { value } }) => setKeyword(value)}
          />
        </div>
        {/* <div className="filter">
          <Select
            style={{ width: "100%" }}
            placeholder="Tipe Event"
            onChange={(e) => setType(e)}
            options={[
              { value: "", label: "All" },
              { value: 1, label: "Upcoming" },
              { value: 0, label: "Past" },
            ]}
          />
        </div> */}
        <div className="filter">
          <DatePicker
            placeholder="Tanggal Awal"
            style={{ width: "100%" }}
            onChange={(e) => setDate((curr) => ({ ...curr, from: e }))}
          />
        </div>
        <div className="filter">
          <DatePicker
            placeholder="Tanggal Akhir"
            style={{ width: "100%" }}
            onChange={(e) => setDate((curr) => ({ ...curr, to: e }))}
          />
        </div>
      </div>
      <Table
        dataSource={dataSource}
        columns={columns}
        size="small"
        loading={isLoading}
        pagination={pagination}
        scroll={{ x: 800 }}
      />
    </div>
  );
};

export default UserMasterBusinessMatching;
