import { LeftCircleTwoTone, RightCircleTwoTone } from "@ant-design/icons";
import {
  Button,
  ConfigProvider,
  Divider,
  Drawer,
  Dropdown,
  Layout,
  Menu,
  Space,
  theme,
} from "antd";
import { decryptCookies } from "components/helper/cookieshelper";
import { logout } from "components/helper/logout";
import { setToken } from "features/auth/auth-slice";
import Cookies from "js-cookie";
import { useState } from "react";
import { BsStars } from "react-icons/bs";
import { FaAngleDown } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { itemsCorporate } from "./itemsCorporate";
import { CgMenuRight } from "react-icons/cg";
import Loading from "components/Loading";
import { setDefaultCorporateMenu } from "features/layout/layout-slice";
import { useContactUsPagination } from "hook/contactUs-hook/apiCalls";

const { useToken } = theme;
const { Sider, Content, Header } = Layout;

const LayoutCorporate = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const [loading, setLoading] = useState(false);

  const { token } = useToken();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const username =
    Cookies.get("dataUser") && JSON.parse(decryptCookies("dataUser")).username;
  const email =
    Cookies.get("dataUser") && JSON.parse(decryptCookies("dataUser")).email;
  const member =
    Cookies.get("dataUser") &&
    JSON.parse(decryptCookies("dataUser")).member?.name;

  const { data } = useContactUsPagination({
    dataTable: { page: 1, limit: 10 },
  });

  const fakeLoading = (time) =>
    new Promise((resolve) => setTimeout(resolve, time));

  const handleLogout = async () => {
    setLoading(true);
    await fakeLoading(2000);
    dispatch(setToken(""));
    setLoading(false);
    logout();
  };

  const handleClick = (param) => {
    navigate(param.key);
    Cookies.set("defaultCorporateMenu", param.key);
    dispatch(setDefaultCorporateMenu(param.key));
  };

  const handleClickMobile = (param) => {
    handleClick(param);
    setOpen(false);
  };

  const defaultCorporateMenu = useSelector(
    (state) => state.layout.defaultCorporateMenu
  );

  const contentStyle = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
  };

  return (
    <ConfigProvider theme={{ components: { Button: { borderRadius: 5 } } }}>
      {loading ? (
        <Loading />
      ) : (
        <Layout className="dark:bg-white dark:text-black">
          <Sider
            theme="dark"
            trigger={null}
            collapsible
            collapsed={collapsed}
            width={230}
            className="hidden xl:block"
          >
            <div className={!collapsed ? "logo" : "small logo"}>Biznids</div>
            {collapsed ? (
              <RightCircleTwoTone
                className="trigger-button"
                onClick={() => setCollapsed(!collapsed)}
              />
            ) : (
              <LeftCircleTwoTone
                className="trigger-button"
                onClick={() => setCollapsed(!collapsed)}
              />
            )}

            <Menu
              theme="dark"
              mode="inline"
              defaultSelectedKeys={[defaultCorporateMenu]}
              items={itemsCorporate}
              onClick={handleClick}
            />
          </Sider>
          <Layout className="bg-gray-100">
            <Header className="bg-white shadow-sm relative border-b pl-4 xl:pl-6">
              <div
                onClick={() => navigate("/")}
                className="w-[50px] h-full flex items-center xl:hidden cursor-pointer"
              >
                <img
                  src={data?.data?.data[0].logo}
                  alt="logo"
                  className="w-[45px] h-[45px]"
                />
              </div>

              <Drawer
                placement="right"
                onClose={() => setOpen(false)}
                open={open}
                width={250}
                styles={{ body: { padding: 0 } }}
              >
                <div className="mobile-menu-wrapper">
                  <Menu
                    theme="light"
                    mode="inline"
                    defaultSelectedKeys={[defaultCorporateMenu]}
                    items={itemsCorporate}
                    onClick={handleClickMobile}
                  />
                </div>
              </Drawer>

              <Dropdown
                trigger={["click"]}
                placement="bottomRight"
                className="absolute right-16 xl:right-6 cursor-pointer h-6 top-[50%] translate-y-[-50%] p-4 border border-gray-300 rounded-md"
                dropdownRender={() => (
                  <div style={contentStyle} className="border border-gray-400">
                    <div className="py-4 px-6">
                      <h2 className="text-[15px]">
                        {username ? username : "Guest"}
                      </h2>
                      <p className="text-xs">{email ? email : "no email"}</p>
                    </div>
                    <Divider style={{ margin: 0 }} />
                    <Divider style={{ margin: 0 }} />
                    <div className="py-4 px-6">
                      <h1 className="font-semibold mb-1">Membership</h1>
                      <Space>
                        <p>{member ? member : ""}</p>
                        <BsStars
                          color={`${member === "Gold" ? "orange" : "gray"}`}
                          className="text-[16px]"
                        />
                      </Space>
                    </div>
                    <Divider style={{ margin: 0 }} />
                    <div className="p-2">
                      <Button
                        type="text"
                        onClick={handleLogout}
                        block
                        className="py-5 px-6 flex items-center bg-black text-white hover:!bg-gray-700 hover:!text-gray-50 duration-200"
                      >
                        Logout
                      </Button>
                    </div>
                  </div>
                )}
              >
                <div className="flex items-center">
                  <p className="font-semibold">
                    {username ? username : "Guest"}
                  </p>
                  <FaAngleDown className="ml-1" />
                </div>
              </Dropdown>
              <CgMenuRight
                className="text-3xl block xl:hidden absolute top-4 right-4 cursor-pointer"
                onClick={() => setOpen(true)}
              />
            </Header>
            <Content className="m-0 xl:m-[18px] px-3 xl:p-6 xl:py-2">
              {children}
            </Content>
          </Layout>
        </Layout>
      )}
    </ConfigProvider>
  );
};

export default LayoutCorporate;
