import { message } from "antd";
import axios from "axios";

const { REACT_APP_HOST_API } = process.env;

export const GetUrl = async (url) => {
  try {
    const { data } = await axios.get(REACT_APP_HOST_API + url);
    return data;
  } catch (error) {
    if (error.response.status === "500") return;

    message.error(`Gagal mengambil data ( ${error.message} )`);
  }
};
